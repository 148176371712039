import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../components/AppHeader';
import CategoryDetails from '../views/CategoryDetails';
import EditProfile from '../views/EditProfile';
import FavoritesPage from '../views/FavoritesPage';
import ItemPage from '../views/ItemPage';
import Profile from '../views/Profile';

export type ProfileStackParamList = {
  profile: undefined;
  editProfile: undefined;
  favorites: undefined
  category: { id: string };
  item: { id: string };
};

const Stack = createNativeStackNavigator<ProfileStackParamList>()

const ProfileNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='profile' screenOptions={{
      header: (props) => <AppHeader initialScreen='profile' {...props} />
    }}>
      <Stack.Screen name="profile" component={Profile} options={{
        title: 'BrasilAmericano | Perfil'
      }} />
      <Stack.Screen name="editProfile" component={EditProfile} options={{
        title: 'BrasilAmericano | Meus Dados'
      }} />
      <Stack.Screen name="favorites" component={FavoritesPage} options={{
        title: 'BrasilAmericano | Favoritos',
        headerTitle: 'Favoritos',
      }} />
      <Stack.Screen name="category" component={CategoryDetails} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
      }} />
      <Stack.Screen name="item" component={ItemPage} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
        headerShown: false
      }} />
    </Stack.Navigator>
  )
}

export default memo(ProfileNavigator)
