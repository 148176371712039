import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { FC, memo } from 'react';
import {Ionicons} from '@expo/vector-icons'
import HomeNavigator from './HomeNavigator';
import MapNavigator from './MapNavigator';
import ProfileNavigator from './ProfileNavigator';
import SearchNavigator from './SearchNavigator';
import { useTheme } from 'native-base';

export type TabParamsList = {
  homePrincipal: undefined;
  searchPrincipal: undefined;
  mapPrincipal: undefined;
  userPrincipal: undefined;
};

const Tab = createBottomTabNavigator<TabParamsList>()

const MainNavigator: FC = () => {
  const theme = useTheme()

  return (
    <Tab.Navigator 
      screenOptions={({route}) => ({
        headerShown: false,
        tabBarIcon: ({focused, color, size}) => {
          let iconName: any = '';

          switch(route.name) {
            case 'homePrincipal':
              iconName = focused ? 'md-home' : 'md-home-outline'
              break
            
            case 'searchPrincipal':
              iconName = focused ? 'md-search' : 'md-search-outline'
              break

            case 'mapPrincipal':
              iconName = focused ? 'md-map' : 'md-map-outline'
              break

            case 'userPrincipal':
              iconName = focused ? 'md-person-circle-sharp' : 'md-person-circle-outline'
              break

            default:
              break
          }

          return <Ionicons name={iconName} color={color} size={size} />
        },
        tabBarActiveTintColor: theme.colors.primary[800],
        tabBarInactiveTintColor: theme.colors.primary[400],
      })}
      backBehavior='none'>
      <Tab.Screen name="homePrincipal" component={HomeNavigator} options={{
        title: 'Home'
      }} />
      <Tab.Screen name="searchPrincipal" component={SearchNavigator} options={{
        title: 'Buscar'
      }} />
      <Tab.Screen name="mapPrincipal" component={MapNavigator} options={{
        title: 'Mapa'
      }} />
      <Tab.Screen name="userPrincipal" component={ProfileNavigator} options={{
        title: 'Perfil'
      }} />
    </Tab.Navigator>
  );
}

export default memo(MainNavigator);
