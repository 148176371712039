import { isNil, isNull, isUndefined } from "lodash"
import { Avatar, Button, Heading, HStack, IconButton, Image, Pressable, Skeleton, Text, useTheme, HamburgerIcon } from "native-base"
import React, { FC, useCallback, useMemo } from "react"
import { Platform } from "react-native"
import { NativeStackHeaderProps } from "@react-navigation/native-stack"
import GoBackIconButton, { BackButtonProps } from './GoBackIconButton'
import { getUserData } from "../redux/auth/selectors"
import { useSelector } from 'react-redux'
import { DrawerActions } from "@react-navigation/native"

export const backButtonIcon = `mi/${Platform.OS === 'ios' ? 'arrow-back-ios' : 'arrow-back'}`

interface AppHeaderProps extends NativeStackHeaderProps {
  initialScreen: string
  hideRightSide?: boolean
}

const AppHeader: FC<AppHeaderProps> = ({initialScreen, back, options, route, navigation, hideRightSide}) => {
  const theme = useTheme()

  const userData = useSelector(getUserData)

  const avatarTitle = useMemo<string>(() => {
    let value: string = ''

    if (userData) {
      if (userData.firstName.trim().length > 0) {
        value += userData.firstName.trim()[0].toUpperCase()
      }
  
      if (userData.lastName.trim().length > 0) {
        value += userData.lastName.trim()[0].toUpperCase()
      }
    }

    return value
  }, [userData])

  const shouldForceGoBack = ['category', 'item', 'editProfile', 'favorites', 'createBusiness', 'claimBusiness'].includes(route.name)
  const isSimpleHeader = ['item'].includes(route.name)

  const backButtonProps: BackButtonProps = {
    isVisible: route.name !== initialScreen && (!isNil(back) || shouldForceGoBack),
    icon: `mi/${Platform.OS === 'ios' ? 'arrow-back-ios' : 'arrow-back'}`,
    action: () => {
      if (navigation.canGoBack()) {
        navigation.goBack()
        return
      }

      if (shouldForceGoBack) {
        navigation.navigate(initialScreen)
      }
    }
  }

  const handleLeftDrawerButtonClick = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer())
  }, [navigation])

  const goHome = useCallback(() => {
    navigation.navigate('homePrincipal', {
      screen: 'home'
    })
  }, [navigation])
  
  const goToProfile = useCallback(() => {
    navigation.navigate('userPrincipal', {
      screen: 'profile'
    })
  }, [navigation])

  if (isSimpleHeader) {
    return (
      <HStack alignItems={'center'} bgColor='transparent' p={2}>
        <GoBackIconButton isVisible={true} icon={backButtonProps.icon} action={backButtonProps.action} iconColor={theme.colors.primary[800]} />
      </HStack>
    )
  }
  
  return (
    <HStack space={2} alignItems="center" bgColor={theme.colors.white} p={2}>
      <HStack flex={1} space={2} alignItems='center'>
        {backButtonProps.isVisible ? (
          <GoBackIconButton isVisible={true} icon={backButtonProps.icon} action={backButtonProps.action} iconColor={theme.colors.primary[800]} />
        ) : (
          <HStack space={2} alignItems='center'>
            <IconButton
              icon={
                <HamburgerIcon color={theme.colors.teal[800]} size={5} />
              }
              onPress={handleLeftDrawerButtonClick}
            />

            <Pressable onPress={() => goHome()}>
              <Image src={require('../assets/icon.png')} size={8} />
            </Pressable>
          </HStack>
        )}
        <Pressable onPress={() => goHome()}>
          <Heading size="xs" color={theme.colors.teal[800]} isTruncated>{options.headerTitle ?? 'BrasilAmericano'}</Heading>
        </Pressable>
      </HStack>

      {!hideRightSide ? (
        <HStack w={'135px'} space={2} alignItems='center' justifyContent={'flex-end'}>
          {isUndefined(userData) ? (
            <Skeleton size="32px" rounded={'full'} />
          ) : isNull(userData) ? (
            <Button size='xs' onPress={goToProfile}>Meu Acesso</Button>
          ) : (
            <Pressable onPress={goToProfile}>
              <Avatar bg={theme.colors.primary[600]} size='sm' source={{uri: userData.photo}} borderColor={theme.colors.primary[800]} borderWidth={1}>
                <Text fontSize='sm' color={theme.colors.white}>{avatarTitle}</Text>
              </Avatar>
            </Pressable>
          )}
        </HStack>
      ) : null}
    </HStack>
  )
}

export default AppHeader
