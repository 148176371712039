import { DocumentSnapshot, runTransaction } from "firebase/firestore";
import { FirestoreSnapshot } from "./FirestoreSnapshot.class";
import * as Analytics from 'expo-firebase-analytics'
import moment from 'moment'

export default class ContactMember extends FirestoreSnapshot<ContactMember> {
  name: string;
  role?: string;
  description?: string
  active: boolean;
  relevance: number

  phone?: string
  sms?: string
  whatsapp?: string
  email?: string

  createdAt: Date
  updatedAt?: Date
  deletedAt?: Date

  constructor(doc: DocumentSnapshot<ContactMember>) {
    super(doc)

    const data = doc.data()!
    this.name = data.name
    this.role = data.role
    this.description = data.description
    this.active = data.active
    this.relevance = data.relevance ?? 0
    this.phone = data.phone
    this.sms = data.sms
    this.whatsapp = data.whatsapp
    this.email = data.email
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.deletedAt = data.deletedAt
  }

  async increaseRelevance(): Promise<number> {
    try {
      this.relevance = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          return this.relevance
        }

        const newRelevance = doc.data().relevance + 1
        transaction.update(this.snapshot.ref, {
          relevance: newRelevance,
          updatedAt: moment().utc().toDate()
        })

        return newRelevance
      })
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('member_error', {
          method: 'increaseRelevance',
          id: this.id,
          message: (e as Error).name ?? (e as Error).message
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.relevance
  }
}
