import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, Platform, View } from 'react-native';
import { getFirestore, getDoc, doc, DocumentReference } from 'firebase/firestore'

import { isNil, isNull, isUndefined } from 'lodash';
import { Heading, HStack, Skeleton, useTheme, VStack, Text, Divider, Badge, Box, Pressable, Button, Image, useToast } from 'native-base';
import * as Analytics from 'expo-firebase-analytics'
import Item from '../models/Item.class';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import ItemHeader from '../components/ItemHeader';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import ActionIconButtons from '../components/ActionIconButtons';
import ContactMember from '../models/ContactMember.class';
import {ScrollView} from 'react-native-gesture-handler'
import { HomeStackParamList } from '../navigators/HomeNavigator';
import { useActionSheet } from '@expo/react-native-action-sheet'
import GoBackIconButton from '../components/GoBackIconButton';
import { backButtonIcon } from '../components/AppHeader';
import MapView, {Marker} from 'react-native-maps';
import { CategoryRef } from '../models/Category.class';
import { useSelector } from 'react-redux';
import { getFavorites, getUser } from '../redux/auth/selectors';
import useAuth from '../hooks/useAuth';
import { getIcon } from '../helpers/icon.helper';
import { openMaps } from '../helpers/maps.helper';
import ItemSlides from '../components/ItemSlides';
import useAnalytics from '../hooks/useAnalytics';
import VerifiedBadge from '../components/VerifiedBadge';

const ItemPage: FC = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<HomeStackParamList, 'item'>>()
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>()
  const firestore = getFirestore()
  const toast = useToast()

  const user = useSelector(getUser)
  const favorites = useSelector(getFavorites)

  const { saveItemPageView, saveItemButtonClick } = useAnalytics()

  const {addFavorite, removeFavorite, isItemFavorited} = useAuth()

  const { showActionSheetWithOptions } = useActionSheet()
  
  const [ item, setItem ] = useState<Item | null>();
  const [members, setMembers] = useState<ContactMember[]>()

  const hasBanner = useMemo<boolean>(() => {
    if (!item) {
      return false
    }

    return !isNil(item.banner) && item.banner.length > 0
  }, [item?.banner])

  const saveActionLog = useCallback((action: string) => {
    if (item) {
      Analytics.logEvent('item_action', {
        itemId: item.id,
        itemName: item.name,
        action,
      })

      item.increaseRelevance()

      saveItemButtonClick(item, action)
    }
  }, [item, saveItemButtonClick])

  const saveMemberActionLog = useCallback((action: string) => {
    if (item) {
      Analytics.logEvent('item_member_action', {
        itemId: item.id,
        itemName: item.name,
        action,
      })

      item.increaseRelevance()

      saveItemButtonClick(item, `member_${action}`)
    }
  }, [item, saveItemButtonClick])

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    const state = navigation.getState()
    if (state && state.routeNames && state.routeNames.length > 0) {
      navigation.navigate(state.routeNames[0])
      return
    }

    (navigation as any).navigate('homePrincipal', {
      screen: 'home'
    })
  }, [navigation])

  const goToCategory = useCallback((category: CategoryRef) => {
    if (navigation) {
      navigation.push('category', {
        id: category.ref.id
      })
    }
  }, [navigation])

  const handleFavoriteClick = useCallback(async (item: Item) => {
    if (!user) {
      try {
        if (toast) {
          toast.show({
            placement: 'bottom',
            render: () => (
              <Box bg={theme.colors.info[800]} px={4} py={2} rounded="full" mb={4}>
                <Text textAlign='center' color='white'>Faça o login ou crie sua conta para começar à adicionar favoritos!</Text>
              </Box>
            ),
          })
        }
      } catch {}

      (navigation as any).navigate('userPrincipal', {
        name: 'profile'
      })
      return
    }

    if (isItemFavorited(item)) {
      await removeFavorite(item.snapshot.ref)
    } else {
      await addFavorite(item.snapshot.ref)
    }
  }, [user, favorites, isItemFavorited, addFavorite, removeFavorite, navigation, toast])

  const handleMapDirectionsClick = useCallback(() => {
    saveActionLog('directions')

    if (!showActionSheetWithOptions) {
      openMaps(item?.addressLine, 'os')
      return
    }

    showActionSheetWithOptions({
      options: (Platform.OS === 'ios' ? ['Abrir no Maps'] : ['Abrir no Google Maps']).concat(['Abrir no Waze', 'Abrir no Navegador', 'Cancelar']),
      cancelButtonIndex: 3,
    }, (buttonIndex => {
      if (!isNil(buttonIndex) && buttonIndex < 3) {
        openMaps(item?.addressLine, ['os', 'waze', 'browser'][buttonIndex] as any)
      }
    }))
  }, [saveActionLog, item?.addressLine])

  useEffect(() => {
    if (item === undefined && firestore && route.params && route.params.id) {
      getDoc(doc(firestore, 'items', route.params.id) as DocumentReference<Item>).then(result => {
        if (!result.exists()) {
          setItem(null)
          return
        }

        const item = new Item(result)
        if (!item.active) {
          Analytics.logEvent('inactive_item_page', {
            message: item.id
          })

          setItem(null)
          return
        }

        setItem(item)
        item.fetchMembers().then(members => setMembers(members))
        saveItemPageView(item, '')
      }).catch((e) => {
        console.error(e)

        try {
          Analytics.logEvent('item_page_error', {
            method: 'getItem',
            message: (e as Error).name ?? (e as Error).message
          })
        } catch (e) {
          console.error(e)
        }
        
        setItem(null)
      })
    }
  }, [item, firestore, route, saveItemPageView])

  useEffect(() => {
    if (item) {
      navigation.setOptions({
        title: `BrasilAmericano | ${item.name}`,
        headerTitle: item.name,
      })
    }
  }, [item, navigation])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      {isUndefined(item) ? (
        <VStack space={4} p={2} bgColor='transparent'>
          <HStack space={2} alignItems='center' bgColor='transparent'>
            <GoBackIconButton isVisible={true} icon={backButtonIcon} action={goBack} iconColor={theme.colors.primary[800]} />
            <Skeleton size={12} rounded={'full'} startColor={theme.colors.primary[800]} />
            <Skeleton h={8} flex={1} rounded={'full'} />
          </HStack>
          <HStack space={2} justifyContent='center' alignItems='center' bgColor='transparent'>
            <Skeleton size={8} rounded={'full'} startColor={theme.colors.primary[800]} />
            <Skeleton size={8} rounded={'full'} startColor={theme.colors.primary[800]} />
            <Skeleton size={8} rounded={'full'} startColor={theme.colors.primary[800]} />
          </HStack>
          <Skeleton.Text />
        </VStack>
      ) : isNull(item) ? (
        <VStack alignItems="center" justifyContent={'center'} space={2} my={4} mx={2}>
          <Heading textAlign={'center'} size="xs">Ops! Não foi possível carregar esta página. Por favor, tente novamente mais tarde.</Heading>
        </VStack>
      ) : (
        <Box flex={1} flexDirection='column'>
          <ScrollView
            style={{
              flex: 1
            }}
          >
            {hasBanner ? (
              <Image source={{ uri: item!.banner! }} resizeMode='cover' width='full' height={[32, 64, 72]} />
            ) : null}

            <VStack flex={1} px={2} pt={2} space={4}>

              <HStack width={'full'} space={2}>
                <GoBackIconButton isVisible={true} icon={backButtonIcon} action={goBack} iconColor={theme.colors.primary[800]} />

                <Box flex={1}>
                  <ItemHeader item={item} forceAvatarSize='md' forceAvatarAlignSelf='center' isFavorited={isItemFavorited(item)} onFavoriteClick={handleFavoriteClick} />
                </Box>
              </HStack>

              <ScrollView>
                {members && Array.isArray(members) && members.length > 0 ? (
                  <ScrollView
                    horizontal={true}
                    style={{
                      marginBottom: 9
                    }}
                  >
                    <HStack space={2} alignItems='stretch'>
                      {members.map((member, index) => (
                        <VStack key={`${member.id}-${index}`} p={2} bgColor={theme.colors.white} rounded='md' space={2}>
                          <Heading size="sm" fontWeight='normal' borderBottomColor={theme.colors.primary[800]} borderBottomWidth={1}>{member.name}</Heading>

                          {member.role && member.role.length > 0 ? (
                            <Text fontSize={'xs'}>{member.role}</Text>
                          ) : null}

                          {member.description && member.description.length > 0 ? (
                            <Text fontWeight={'thin'} fontSize='xs'>{member.description}</Text>
                          ) : null}

                          <ActionIconButtons
                            email={member.email}
                            phone={member.phone}
                            sms={member.sms}
                            whatsapp={member.whatsapp}
                            logCallback={saveMemberActionLog}
                            showActionSheetWithOptions={showActionSheetWithOptions}
                          />
                        </VStack>
                      ))}
                    </HStack>
                  </ScrollView>
                ) : null}

                <ItemSlides slides={item.slides} />

                <VStack p={2} space={4} bgColor={theme.colors.white} rounded='md' marginBottom={2}>
                  <HStack alignItems={'center'} space={2} flexWrap='wrap'>
                    {item.verified ? <VerifiedBadge /> : null}

                    <Pressable onPress={() => goToCategory(item.category)}>
                      <Badge key={item.category.name} variant='solid' rounded={'full'} colorScheme='primary'>{item.category.name}</Badge>
                    </Pressable>
                    
                    {item.subCategories && item.subCategories.length > 0 ? item.subCategories.map(subCategory => (
                      <Badge key={subCategory.name} variant='subtle' rounded={'full'}>{subCategory.name}</Badge>
                    )) : null}
                  </HStack>
                  
                  {item.addressLine && item.addressLine.length > 0 ? (
                    <HStack alignItems="stretch" space="2" flexWrap="wrap">
                      <Divider orientation="vertical" width={1} height={'auto'} />
                      <Text fontSize={'xs'} fontWeight='thin'>{item.addressLine}</Text>
                    </HStack>
                  ) : null}

                  {item.description && item.description.length > 0 ? (
                    <Text fontWeight={'thin'} fontSize='xs'>{item.description}</Text>
                  ) : null}
                </VStack>
                
                {item.coords ? (
                  <Box flexDirection={'column'} bgColor={theme.colors.white} height={64} rounded='md' marginBottom={4} position='relative'>
                    <MapView
                      style={{
                        width: Dimensions.get('window').width,
                        flex: 1
                      }}
                      initialRegion={{
                        latitude: item.coords.latitude,
                        longitude: item.coords.longitude,
                        latitudeDelta: 0,
                        longitudeDelta: 0
                      }}
                      showsMyLocationButton={false}
                      showsPointsOfInterest={false}
                      showsBuildings={false}
                      showsTraffic={false}
                      showsIndoors={false}
                    >
                      <Marker
                        identifier={item.id}
                        coordinate={item.coords}
                        opacity={1}
                      />
                    </MapView>

                    <Button
                      variant={'solid'}
                      rounded='full'
                      bgColor={theme.colors.primary[800]}
                      onPress={handleMapDirectionsClick}
                      leftIcon={getIcon('mci/map-marker', {size: 16, color: theme.colors.white}) ?? undefined}
                      size={'xs'}
                      position='absolute'
                      right={2}
                      top={2}
                    >
                      Como chegar?
                    </Button>
                  </Box>
                ) : null}
              </ScrollView>
            </VStack>
          </ScrollView>

          <Box
            bgColor={theme.colors.white}
          >
            <ScrollView
              horizontal={true}
              style={{
                padding: 8
              }}
              contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            >
              <ActionIconButtons
                website={item.website}
                email={item.email}
                phone={item.phone}
                sms={item.sms}
                whatsapp={item.whatsapp}
                instagram={item.instagram}
                facebook={item.facebook}
                youtube={item.youtube}
                addressLine={item.addressLine}
                logCallback={saveActionLog}
                showActionSheetWithOptions={showActionSheetWithOptions}
                showLabels={true}
                noMarginBottom={true}
              />
            </ScrollView>
          </Box>
        </Box>
      )}
    </View>
  );
}

export default memo(ItemPage);
