import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { collection, CollectionReference, getDocs, getFirestore, query, orderBy } from 'firebase/firestore';
import State from '../models/State.class';

interface UseStateResponse {
  states?: State[]
}

const useStates = (): UseStateResponse => {
  const firestore = getFirestore()

  const [states, setStates] = useState<State[]>()

  useEffect(() => {
    if (isUndefined(states)) {
      getDocs(query(collection(firestore, 'states') as CollectionReference<State>, orderBy('name', 'asc'))).then(result => {
        const newStates: State[] = []
        
        result.forEach(doc => newStates.push(new State(doc)))
  
        setStates(newStates)
      })
    }
  }, [states])

  return {states}
}

export default useStates
