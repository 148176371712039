import { Platform } from "react-native";
import * as WebBrowser from 'expo-web-browser'

export const openLink = (link?: string | null, newTab: boolean = true) => {
  if (!link || link.length === 0) {
    return;
  }

  if(Platform.OS == 'web'){
    window.open(link, newTab ? '_blank' : '_self');
  } else {
    WebBrowser.openBrowserAsync(link)
  }
}
