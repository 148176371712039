import { isNil } from 'lodash';
import { Platform } from "react-native"
import { openLink } from "./link.helper"

export const openMaps = (addressLine?: string | null, mapType: 'os' | 'waze' | 'browser' = 'os') => {
  if (!isNil(addressLine) && addressLine.length > 0) {
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${addressLine}`
    const appleMapsURL = `http://maps.apple.com/?q=${addressLine}`
    const wazeMapsURL = `https://waze.com/ul?q=${addressLine}`

    if (mapType === 'browser') {
      openLink(googleMapsURL)
    } else if (mapType === 'os') {
      openLink(Platform.OS === 'ios' ? appleMapsURL : googleMapsURL)
    } else {
      openLink(wazeMapsURL)
    }
  }
}
