import React, { FC, memo, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { Category } from '../models/Category.class';
import { getFirestore, getDocs, collection, query, where, CollectionReference } from 'firebase/firestore'
import { sortByOrderASC } from '../helpers/sort.helper';
import CategoryListItem from '../components/CategoryListItem';

import { HStack, Skeleton, useTheme, VStack } from 'native-base';
import * as Analytics from 'expo-firebase-analytics'
import MoreRelevantItems from '../components/MoreRelevantItems';
import { ScrollView } from 'react-native-gesture-handler';

const Home: FC = () => {
  const theme = useTheme()
  
  const firestore = getFirestore()
  
  const [ categories, setCategories ] = useState<Category[]>();

  useEffect(() => {
    if (categories === undefined) {
      getDocs(query(collection(firestore, "categories") as CollectionReference<Category>, where('active', '==', true))).then(result => {
        const newCategories: Category[] = []
        
        result.forEach(doc => newCategories.push(new Category(doc)))

        newCategories.sort(sortByOrderASC)

        setCategories(newCategories)
      }).catch(e => {
        console.error(e)
      
        try {
          Analytics.logEvent('home_error', {
            method: 'getCategories',
            message: (e as Error).name ?? (e as Error).message
          })
        } catch (e) {
          console.error(e)
        }
        
        setCategories([])
      })
    }
  }, [categories, firestore])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <ScrollView>
        <MoreRelevantItems />

        {categories === undefined ? (
          <VStack space={2} p={2}>
            {Array(4).fill(0).map((_, index) => (
              <HStack key={index} space={2} alignItems='center' opacity={1 - index * 3/10}>
                <Skeleton size={8} rounded={'full'} startColor={theme.colors.primary[800]} />
                <Skeleton h={8} flex={1} rounded={'full'} />
              </HStack>
            ))}
          </VStack>
        ) : (
          <FlatList
            data={categories}
            keyExtractor={item => item.id}
            style={{
              paddingHorizontal: 10,
              paddingVertical: 10
            }}
            renderItem={({item}) => <CategoryListItem category={item} />}
          />
        )}
      </ScrollView>
    </View>
  );
}

export default memo(Home);
