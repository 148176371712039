import {MaterialCommunityIcons, MaterialIcons, Entypo, Octicons, Ionicons, FontAwesome, FontAwesome5, SimpleLineIcons, AntDesign, EvilIcons} from '@expo/vector-icons'
import React from 'react'
import { View } from 'react-native'

export interface GetIconProps {
  color?: string,
  opacity?: number,
  size?: number,
  marginLeft?: number,
  marginRight?: number
}

export const getIcon = (iconName: string, props?: GetIconProps): JSX.Element | null => {
  if (!iconName || iconName.length === 0) {
    return null
  }

  const iconParams: any = {
    name: iconName as any,
    size: props?.size ?? 50,
    style: {
      opacity: props?.opacity ?? 1
    }
  }

  if (props?.color) {
    iconParams.color = props.color
  }

  if (props?.marginLeft) {
    iconParams.marginLeft = props.marginLeft
  }

  if (props?.marginRight) {
    iconParams.marginRight = props.marginRight
  }

  let Icon;

  if (!iconName.includes('/')) {
    Icon = <MaterialCommunityIcons {...iconParams} />
  } else {
    const pieces = iconName.split('/')
    const iconCollection = pieces[0]
    iconParams.name = pieces[1] as any
  
    switch(iconCollection) {
      case 'mci':
        Icon = <MaterialCommunityIcons {...iconParams} />
        break
  
      case 'mi':
        Icon = <MaterialIcons {...iconParams} />
        break
  
      case 'etp':
        Icon = <Entypo {...iconParams} />
        break
  
      case 'oct':
        Icon = <Octicons {...iconParams} />
        break
  
      case 'ion':
        Icon = <Ionicons {...iconParams} />
        break

      case 'fa':
        Icon = <FontAwesome {...iconParams} />
        break

      case 'fa5':
        Icon = <FontAwesome5 {...iconParams} />
        break

      case 'sli':
        Icon = <SimpleLineIcons {...iconParams} />
        break

      case 'ant':
        Icon = <AntDesign {...iconParams} />

      case 'evil':
        Icon = <EvilIcons {...iconParams} />
  
      default:
        Icon = null
        break
    }
  }


  return (
    <View style={{
      width: iconParams.size,
      height: iconParams.size,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {Icon}
    </View>
  )
}
