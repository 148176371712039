import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { isNil, isNull } from 'lodash';
import { Avatar, HStack, useTheme, VStack, Text, Skeleton, FlatList, Box, ScrollView } from 'native-base';
import React, { FC, memo, useMemo, useState } from 'react';
import { View } from 'react-native';
import ProfileListItem, { ProfileListItemProps } from '../components/ProfileListItem';
import { openLink } from '../helpers/link.helper';
import { ProfileStackParamList } from '../navigators/ProfileNavigator';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { useSelector } from 'react-redux';
import { getUser, getUserData } from '../redux/auth/selectors';
import useAuth from '../hooks/useAuth';

export type ProfileAuthState = 'signin' | 'signup' | 'resetpassword'

const Profile: FC = () => {
  const user = useSelector(getUser)
  const userData = useSelector(getUserData)

  const {signOut} = useAuth()

  const theme = useTheme()

  const navigation = useNavigation<NativeStackNavigationProp<ProfileStackParamList>>()

  const [authMode, setAuthMode] = useState<ProfileAuthState>('signin')

  const [profileMenu] = useState<ProfileListItemProps[]>([
    {
      leftIcon: {
        iconName: 'mci/briefcase-variant',
        color: theme.colors.primary[600],
        size: 24
      },
      title: 'Portal do Empreendedor',
      titleColor: theme.colors.primary[600],
      description: 'Gerencie seus empreendimentos no BrasilAmericano',
      action: () => {
        openLink('https://admin.brasilamericano.com')
      }
    },
    {
      leftIcon: {
        iconName: 'mi/info-outline',
        color: theme.colors.primary[600],
        size: 24
      },
      title: 'Meus Dados',
      description: 'Informações da Conta',
      action: () => {
        if (navigation) {
          navigation.push('editProfile')
        }
      }
    },
    {
      leftIcon: {
        iconName: 'mi/favorite',
        color: theme.colors.primary[600],
        size: 24
      },
      title: 'Favoritos',
      description: 'Minha lista de favoritos',
      action: () => {
        if (navigation) {
          navigation.push('favorites')
        }
      }
    },
    {
      leftIcon: {
        iconName: 'mci/exit-to-app',
        color: theme.colors.primary[600],
        size: 24
      },
      title: 'Sair',
      description: 'Fazer logout',
      action: () => {
        signOut()
      }
    },
  ])

  const isUserDataLoaded = useMemo<boolean>(() => {
    return !isNil(userData)
  }, [userData])

  if (isNull(user)) {
    return authMode === 'resetpassword' ? (
      <ResetPassword changeStage={setAuthMode} />
    ) : authMode === 'signup' ? (
      <SignUp changeStage={setAuthMode} />
    ) : (
      <SignIn changeStage={setAuthMode} />
    );
  }

  return (
    <View style={{
      flex: 1,
      overflow: 'hidden'
    }}>
      <ScrollView flex={1}>
        <VStack space={4} p={2} bgColor='transparent'>
          <HStack space={4} width='full' mb={4}>
            <Skeleton h={'48px'} w={'48px'} rounded='full' isLoaded={isUserDataLoaded}>
              <Avatar bg={theme.colors.primary[600]} size='md' source={{uri: userData?.photo}} borderColor={theme.colors.primary[800]} borderWidth={1}>
                <Text color={theme.colors.white}>{userData?.firstName ? userData.firstName.trim()[0].toUpperCase() : ''}{userData?.lastName ? userData.lastName.trim()[0].toUpperCase() : ''}</Text>
              </Avatar>
            </Skeleton>

            <VStack height={'full'} justifyContent='center'>
              <Skeleton.Text lines={1} height={'24px'} width='100px' isLoaded={isUserDataLoaded}>
                <Text fontSize='md' color={theme.colors.primary[600]} bold>{userData?.firstName ?? ''}{userData?.lastName ? ` ${userData.lastName}` : ''}</Text>
              </Skeleton.Text>

              <Skeleton.Text lines={1} height={'18px'} width='100px' isLoaded={isUserDataLoaded}>
                <Text fontSize='xs' color={theme.colors.gray[500]}>{userData?.email}</Text>
              </Skeleton.Text>
            </VStack>
          </HStack>
        
          {!isNil(user) ? (
            <FlatList
              data={profileMenu}
              keyExtractor={(item) => item.title}
              renderItem={({item}) => (
                <Box marginBottom={4}>
                  <ProfileListItem {...item} />
                </Box>
              )}
            />
          ) : null}
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(Profile);
