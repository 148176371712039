import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { View, ScrollView } from 'react-native';
import { VStack, FormControl, Input, WarningOutlineIcon, Button, Avatar, Text, HStack, useDisclose, Actionsheet, useTheme } from 'native-base';
import { doc, DocumentReference, getFirestore, updateDoc } from 'firebase/firestore';
import { User as FirebaseUser } from 'firebase/auth';
import * as Analytics from 'expo-firebase-analytics'
import { isValidInput } from '../helpers/validate.helper';
import { User } from '../models/User.class';
import { isNil } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ProfileStackParamList } from '../navigators/ProfileNavigator';
import useStorage from '../hooks/useStorage';
import * as ImagePicker from 'expo-image-picker';
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux';
import { getUser, getUserData } from '../redux/auth/selectors';
import moment from 'moment';

const EditProfile: FC = () => {
  const theme = useTheme()

  const user = useSelector(getUser)
  const userData = useSelector(getUserData)

  const firestore = getFirestore()

  const navigation = useNavigation<NativeStackNavigationProp<ProfileStackParamList>>()

  const { uploadBlob } = useStorage()

  const [photo, setPhoto] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [isUploading, setUploading] = useState<boolean>(false)

  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclose();

  const avatarTitle = useMemo<string>(() => {
    let value: string = ''

    if (firstName.trim().length > 0) {
      value += firstName.trim()[0].toUpperCase()
    }

    if (lastName.trim().length > 0) {
      value += lastName.trim()[0].toUpperCase()
    }

    return value
  }, [firstName, lastName])

  const goToProfile = useCallback(() => {
    if (navigation) {
      navigation.push('profile')
    }
  }, [navigation])

  const uploadImageAsync = useCallback(async (uri: string) => {
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    return uploadBlob(uuidv4(), blob, 'profiles/')
  }, [uploadBlob])

  const handleImagePicked = useCallback(async (pickerResult: ImagePicker.ImagePickerResult) => {
    try {
      setUploading(true)

      if (!pickerResult.cancelled) {
        const uploadUrl = await uploadImageAsync(pickerResult.uri);
        
        setPhoto(uploadUrl)
      }
    } catch (e) {
      // ...
    } finally {
      setUploading(false)
    }
  }, [uploadImageAsync])

  const takePhoto = useCallback(async () => {
    onClose();

    let pickerResult = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      aspect: [1, 1],
    });

    return handleImagePicked(pickerResult);
  }, [onClose, handleImagePicked]);

  const pickImage = useCallback(async () => {
    onClose();

    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [1, 1],
    });

    return handleImagePicked(pickerResult);
  }, [onClose, handleImagePicked]);
  
  const updateProfile = useCallback(async (user: FirebaseUser, firstName: string, lastName: string, photo: string) => {
    if (firestore && user) {
      return updateDoc(doc(firestore, 'users', user.uid) as DocumentReference<User>, {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        photo,
        updatedAt: moment().utc().toDate()
      })
    }

    try {
      Analytics.logEvent('update_profile_error', {
        method: 'updateProfile',
        message: 'invalid firestore or user'
      })
    } catch (e) {
      console.error(e)
    }
  }, [firestore])

  const handleEditProfileClick = useCallback(async () => {
    if (!user) {
      return
    }

    setSubmitted(true)
    setLoading(true)

    if (isValidInput(firstName, 1) && isValidInput(lastName, 1)) {
      try {
        await updateProfile(user, firstName, lastName, photo)
        goToProfile()
      } catch (e) {
        console.error(e)
        setLoading(false)

        try {
          Analytics.logEvent('update_profile_error', {
            method: 'handleEditProfileClick',
            photo,
            firstName,
            lastName,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
      }
    } else {
      setLoading(false)
    }
  }, [firstName, lastName, photo, updateProfile, goToProfile])

  useEffect(() => {
    if (loading && !isNil(user) && !isNil(userData)) {
      setFirstName(userData.firstName ?? '')
      setLastName(userData.lastName ?? '')
      setPhoto(userData.photo ?? '')
      setLoading(false)
    }
  }, [user, userData])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <ScrollView style={{
        flex: 1,
        minHeight: '100%',
        padding: 20,
      }}>
        <VStack space={4} alignItems='center'>
          <VStack space={1} justifyContent='center' alignItems={'center'}>
            {photo && photo.length > 0 ? (
              <Avatar bg={theme.colors.primary[600]} size='xl' source={{uri: photo}} borderColor={theme.colors.primary[800]} borderWidth={1} />
            ) : (
              <Avatar bg={theme.colors.primary[600]} size='xl'>
                <Text color={theme.colors.white} fontSize='xl'>{avatarTitle}</Text>
              </Avatar>
            )}

            <HStack space={1} justifyContent='center' alignItems={'center'}>
              <Button fontSize='xs' isLoading={isUploading} rounded='full' variant='link' onPress={onOpen} disabled={loading} colorScheme='coolGray'>
                Escolher Foto de Perfil
              </Button>

              {photo ? (
                <Button fontSize='xs' isLoading={isUploading} rounded='full' variant='link' onPress={() => setPhoto('')} disabled={loading} colorScheme='coolGray'>
                  Remover
                </Button>
              ) : null}

              <Actionsheet isOpen={isOpen} onClose={onClose}>
                <Actionsheet.Content>
                  <Actionsheet.Item onPress={pickImage}>Escolher da Biblioteca</Actionsheet.Item>
                  <Actionsheet.Item onPress={takePhoto}>Abrir Camera</Actionsheet.Item>
                  <Actionsheet.Item onPress={onClose}>Cancelar</Actionsheet.Item>
                </Actionsheet.Content>
              </Actionsheet>
            </HStack>
          </VStack>

          <FormControl isRequired isInvalid={submitted && !isValidInput(firstName, 1)}>
            <VStack>
              <FormControl.Label>Nome</FormControl.Label>
              <Input type="text" variant={'rounded'} value={firstName} onChangeText={text => setFirstName(text)} keyboardType='default' autoCapitalize='words' autoCompleteType="name" />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {`Por favor, insira um nome válido.`}
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={submitted && !isValidInput(lastName, 1)}>
            <VStack>
              <FormControl.Label>Sobrenome</FormControl.Label>
              <Input type="text" variant={'rounded'} value={lastName} onChangeText={text => setLastName(text)} keyboardType='default' autoCapitalize='words' autoCompleteType='name' />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {`Por favor, insira um sobrenome válido.`}
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isReadOnly={true}>
            <VStack>
              <FormControl.Label>Email</FormControl.Label>
              <Input type="text" variant={'rounded'} placeholder='Email' value={userData?.email ?? ''} editable={false} keyboardType='email-address' width={'full'} isDisabled={true} />
            </VStack>
          </FormControl>

          <Button isLoading={loading} isLoadingText={submitted ? 'Salvando' : 'Carregando'} width={'full'} rounded='full' onPress={handleEditProfileClick}>
            Salvar
          </Button>

          <Button width={'full'} rounded='full' variant='link' onPress={goToProfile} disabled={loading}>
            Voltar
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(EditProfile);
