import React, { FC, memo, useCallback, useMemo } from 'react';
import Item, { ItemCoupon } from '../models/Item.class';
import * as Analytics from 'expo-firebase-analytics'
import { Badge, HStack, PresenceTransition, Pressable, Text, useTheme, VStack, Image, ScrollView } from 'native-base';
import ActionIconButtons from './ActionIconButtons';
import {
  useCollapsible,
  AnimatedSection,
} from 'reanimated-collapsible-helpers';
import ItemHeader from './ItemHeader';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { HomeStackParamList } from '../navigators/HomeNavigator';
import { ActionSheetOptions } from '@expo/react-native-action-sheet'
import ItemSlides from './ItemSlides';
import { isNil } from 'lodash';
import { Category } from '../models/Category.class';
import useAnalytics from '../hooks/useAnalytics';
import VerifiedBadge from './VerifiedBadge';

export const actionIconSize = 25

interface ListItemProps {
  item: Item,
  showContactsModal: (item: Item) => void
  showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void;
  isFavorited?: boolean
  onFavoriteClick?: (item: Item) => void
  category?: Category
}

const ListItem: FC<ListItemProps> = ({item, showContactsModal, showActionSheetWithOptions, isFavorited, onFavoriteClick, category}) => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>()

  const { animatedHeight, onPress, onLayout, state } = useCollapsible(
    {}
  );

  const { saveItemOpenDetails, saveItemButtonClick } = useAnalytics()

  const activeCoupon = useMemo<ItemCoupon | undefined>(() => {
    const hasCoupons = item.coupons && item.coupons.length > 0
    return hasCoupons ? (item.coupons?.find(coupon => coupon.active && !coupon.deletedAt) ?? undefined) : undefined
  }, [item.coupons])

  const handleOnPress = useCallback(() => {
    saveItemOpenDetails(item, category)
    onPress()
  }, [onPress, item, category])

  const handleOpenContactsModal = useCallback(() => {
    showContactsModal(item)
  }, [showContactsModal, item])

  const saveActionLog = useCallback((action: string) => {
    Analytics.logEvent('item_action', {
      itemId: item.id,
      itemName: item.name,
      action,
    })

    item.increaseRelevance()

    saveItemButtonClick(item, action, category)
  }, [item, category])

  return (
    <PresenceTransition visible={true} initial={{
      opacity: 0,
      scale: 0
    }} animate={{
      opacity: 1,
      scale: 1,
      transition: {
        duration: 250
      }
    }}>
      <VStack mx={2} marginBottom={2} p={2} bgColor={theme.colors.white} rounded='md'>
        <Pressable onPress={handleOnPress}>
          <ItemHeader item={item} expandableState={state} isFavorited={isFavorited} onFavoriteClick={onFavoriteClick} />
        </Pressable>

        <AnimatedSection
          animatedHeight={animatedHeight}
          onLayout={onLayout}
          state={state}
        >
          <VStack space={2} marginTop={2}>
            {!isNil(item.banner) && item.banner.length > 0 ? (
              <Image source={{ uri: item.banner }} resizeMode='cover' rounded='md' width='full' height={[32, 64, 72]} />
            ) : !isNil(item.slides) && item.slides.length > 0 ? (
              <ItemSlides slides={item.slides} additionalMargin={true} />
            ) : null}

            {item.verified ? (
              <HStack>
                <VerifiedBadge />
              </HStack>
            ) : null}

            {item.description && item.description.length > 0 ? (
              <Text fontWeight={'thin'} fontSize='xs'>{item.description}</Text>
            ) : null}
    
            {item.addressLine && item.addressLine.length > 0 ? (
              <Text fontSize={'xs'} fontWeight='thin' color={theme.colors.gray[600]}>{item.addressLine}</Text>
            ) : null}
    
            {(item.subCategories && item.subCategories.length > 0) ? (
              <HStack alignItems={'center'} space={2} flexWrap='wrap'>
                {item.subCategories.map(subCategory => (
                  <Badge key={subCategory.name} variant='subtle' rounded={'full'}>{subCategory.name}</Badge>
                ))}
              </HStack>
            ) : null}

            <ScrollView
              horizontal={true}
              contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            >
              <ActionIconButtons
                id={item.id}
                navigation={navigation}
                website={item.website}
                email={item.email}
                phone={item.phone}
                sms={item.sms}
                whatsapp={item.whatsapp}
                instagram={item.instagram}
                facebook={item.facebook}
                youtube={item.youtube}
                addressLine={item.addressLine}
                membersAction={item.membersQuantity > 0 ? handleOpenContactsModal : undefined}
                logCallback={saveActionLog}
                activeCoupon={activeCoupon}
                showActionSheetWithOptions={showActionSheetWithOptions}
                showLabels={true}
                noMarginBottom={true}
              />
            </ScrollView>
          </VStack>
        </AnimatedSection>
      </VStack>
    </PresenceTransition>
  );
}

export default memo(ListItem);
