import { isNil } from 'lodash';
import { Avatar, Badge, Heading, HStack, Icon, IconButton, Text, useTheme, VStack } from 'native-base';
import React, { FC, memo, useMemo } from 'react';
import { getIcon } from '../helpers/icon.helper';
import Item, { ItemCoupon } from '../models/Item.class';
import { State } from 'reanimated-collapsible-helpers'
import { SimpleLineIcons, MaterialIcons } from '@expo/vector-icons'
import { ResponsiveValue } from 'native-base/lib/typescript/components/types';
import { FlexAlignType } from 'react-native';

interface ItemHeaderProps {
  item: Item
  expandableState?: State
  forceAvatarSize?: string | number
  forceAvatarAlignSelf?: ResponsiveValue<'auto' | FlexAlignType | undefined>
  isFavorited?: boolean
  onFavoriteClick?: (item: Item) => void
}

const ItemHeader: FC<ItemHeaderProps> = ({item, expandableState, forceAvatarSize, forceAvatarAlignSelf, isFavorited, onFavoriteClick}) => {
  const theme = useTheme()

  const activeCoupon = useMemo<ItemCoupon | undefined>(() => {
    const hasCoupons = item.coupons && item.coupons.length > 0
    return hasCoupons ? (item.coupons?.find(coupon => coupon.active && !coupon.deletedAt) ?? undefined) : undefined
  }, [item.coupons])

  return (
    <HStack space={2} justifyContent={'flex-start'} alignItems={'center'} flexWrap='wrap'>
      {item.verified ? (
        <Icon as={MaterialIcons} name='verified' size={6} color={theme.colors.info[400]} />
      ) : null}

      {item.logo ? (
        <Avatar
          source={{ uri: item.logo }}
          bgColor={theme.colors.white}
          borderWidth={1}
          borderColor={theme.colors.primary[800]}
          size={!isNil(forceAvatarSize) ? forceAvatarSize : activeCoupon ? 'md' : 'sm'}
          alignSelf={!isNil(forceAvatarAlignSelf) ? forceAvatarAlignSelf : 'center'}
        >
          <Text p={2} color={theme.colors.primary[800]} numberOfLines={1} ellipsizeMode='tail' isTruncated={true} fontSize={'xs'}>{item.id}</Text>
        </Avatar>
      ) : null}
      <VStack flex={1} space={1}>
        <Heading size="xs" fontWeight='normal' w={'full'}>{item.name}</Heading>

        {activeCoupon ? (
          <HStack>
            <Badge leftIcon={getIcon('mi/local-offer', {color: theme.colors.primary[400], size: 9})!} variant={'outline'} colorScheme='primary' rounded={'full'} justifyContent={'space-between'} py={'0.5px'} px={'4px'} alignItems={'center'}>
              <Text fontSize={'9px'} color={theme.colors.primary[600]}>{activeCoupon.name} - {`${activeCoupon.prefix ?? ''}${activeCoupon.amount}${activeCoupon.suffix ?? ''}`}</Text>
            </Badge>
          </HStack>
        ) : null}
      </VStack>

      {!isNil(onFavoriteClick) ? (
        <IconButton
          icon={
            <Icon 
              as={MaterialIcons} 
              size={!isNil(forceAvatarSize) ? forceAvatarSize : activeCoupon ? 'md' : 'sm'} 
              name={isFavorited ? 'favorite' : 'favorite-outline'}
            />
          }
          colorScheme='primary'
          onPress={() => onFavoriteClick(item)}
          rounded='full'
          variant='ghost'
        />
      ) : null}

      {!isNil(expandableState) ? (
        <Icon
          marginLeft={2}
          size={3}
          color={theme.colors.primary[800]}
          as={SimpleLineIcons}
          name={expandableState === 'expanded' ? 'arrow-up' : 'arrow-down'}
        />
      ) : null}
      
    </HStack>
  );
}

export default memo(ItemHeader);
