import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../components/AppHeader';
import CategoryDetails from '../views/CategoryDetails';
import Search from '../views/Search';
import ItemPage from '../views/ItemPage';

export type SearchStackParamList = {
  search: undefined;
  category: { id: string };
  item: { id: string };
};

const Stack = createNativeStackNavigator<SearchStackParamList>()

const SearchNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='search' screenOptions={{
      header: (props) => <AppHeader initialScreen='search' {...props} />
    }}>
      <Stack.Screen name="search" component={Search} options={{
        title: 'BrasilAmericano | Buscar',
        headerShown: true
      }} />
      <Stack.Screen name="category" component={CategoryDetails} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
      }} />
      <Stack.Screen name="item" component={ItemPage} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
        headerShown: false
      }} />
    </Stack.Navigator>
  )
}

export default memo(SearchNavigator)
