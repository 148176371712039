import { createDrawerNavigator } from '@react-navigation/drawer';
import { FC, memo } from 'react';
import LeftDrawerContent from '../drawers/LeftDrawerContent';
import HelpNavigator from './HelpNavigator';
import MainNavigator from './MainNavigator';

const LeftDrawer = createDrawerNavigator()

const LeftDrawerNavigator: FC = () => {

  return (
    <LeftDrawer.Navigator
      initialRouteName="mainNavigator" 
      screenOptions={{
        drawerType: 'back',
        headerShown: false
      }}
      drawerContent={props => <LeftDrawerContent {...props} />}>
      <LeftDrawer.Screen name="mainNavigator" component={MainNavigator} />
      <LeftDrawer.Screen name="helpNavigator" component={HelpNavigator} />
    </LeftDrawer.Navigator>
  );
}

export default memo(LeftDrawerNavigator);
