import { FlatList, Image } from 'native-base';
import React, { FC, memo, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { getSlideSize } from '../helpers/image.helper';

interface ItemSlidesProps {
  slides: string[]
  additionalMargin?: boolean
}

const ItemSlides: FC<ItemSlidesProps> = ({slides, additionalMargin}) => {

  const { width: windowWidth } = useWindowDimensions()

  const slideWidth = useMemo<string>(() => {
    return `${getSlideSize((slides).length, windowWidth, additionalMargin)}px`
  }, [slides, windowWidth, additionalMargin])

  return (
    <FlatList
      data={slides}
      keyExtractor={slide => slide}
      horizontal={true}
      marginBottom={2}
      renderItem={({item: slide, index}) => (
        <Image source={{ uri: slide }} resizeMode='cover' rounded='md' width={slideWidth} height={[32, 64, 72, 96]} marginRight={index < slides.length - 1 ? 2 : 0} />
      )}
    />
  );
}

export default memo(ItemSlides);
