import { DocumentReference, DocumentSnapshot, GeoPoint } from "firebase/firestore"
import { FirestoreSnapshot } from "./FirestoreSnapshot.class"
import { StateRef } from "./State.class"

export interface CityRef {
  ref: DocumentReference<City>
  name: string
}

export default class City extends FirestoreSnapshot<City> {
  name: string
  state: StateRef
  coords?: GeoPoint | null
  geoHash?: string | null
 
  constructor(doc: DocumentSnapshot<City>) {
    super(doc)

    const data = doc.data()!
    this.name = data.name
    this.state = data.state
    this.coords = data.coords
    this.geoHash = data.geoHash
  }
}
