import { DocumentReference } from 'firebase/firestore';
import Item from './../../models/Item.class';
import { User as FirebaseUser } from 'firebase/auth'
import { User } from '../../models/User.class';

export interface AuthState {
  user?: FirebaseUser | null;
  userData?: User | null;
  favorites?: DocumentReference<Item>[];
}

export default {} as AuthState;
