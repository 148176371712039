import { Badge, Divider, HStack, VStack, Text, Avatar, Heading, useTheme, Icon, IconButton } from 'native-base';
import React, { FC, memo, useCallback, useMemo } from 'react';
import Item, { ItemCoupon } from '../models/Item.class';
import ActionIconButtons from './ActionIconButtons';
import * as Analytics from 'expo-firebase-analytics'
import { getIcon } from '../helpers/icon.helper';
import { HomeStackParamList } from '../navigators/HomeNavigator';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { ActionSheetOptions } from '@expo/react-native-action-sheet'
import { isNil } from 'lodash';
import { MaterialIcons } from '@expo/vector-icons'
import useAnalytics from '../hooks/useAnalytics';

interface MapItemProps {
  item: Item
  handleOpenContactsModal: () => void;
  navigation: BottomTabNavigationProp<HomeStackParamList, keyof HomeStackParamList>
  showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void;
  isFavorited?: boolean
  onFavoriteClick?: (item: Item) => void
}

const MapItem: FC<MapItemProps> = ({item, navigation, isFavorited, onFavoriteClick}) => {
  const theme = useTheme();

  const { saveItemButtonClick } = useAnalytics()

  const activeCoupon = useMemo<ItemCoupon | undefined>(() => {
    const hasCoupons = item.coupons && item.coupons.length > 0
    return hasCoupons ? (item.coupons?.find(coupon => coupon.active && !coupon.deletedAt) ?? undefined) : undefined
  }, [item.coupons])

  const saveActionLog = useCallback((action: string) => {
    Analytics.logEvent('item_action', {
      itemId: item.id,
      itemName: item.name,
      action,
    })

    item.increaseRelevance()

    saveItemButtonClick(item, action)
  }, [item, saveItemButtonClick])

  return (
    <VStack height={"full"} justifyContent="space-between" space={2} marginTop={2} bgColor={theme.colors.white} rounded="md" padding={2}>
      <HStack space={2} justifyContent={'flex-start'} alignItems={'center'} flexWrap='wrap'>
        {item.verified ? (
          <Icon as={MaterialIcons} name='verified' size={6} color={theme.colors.info[400]} />
        ) : null}
        
        {item.logo ? (
          <Avatar
            source={{ uri: item.logo }}
            bgColor={theme.colors.white}
            borderWidth={1}
            borderColor={theme.colors.primary[800]}
            size={activeCoupon ? 'md' : 'sm'}
            alignSelf={'center'}
          >
            <Text p={2} color={theme.colors.primary[800]} numberOfLines={1} ellipsizeMode='tail' isTruncated={true} fontSize={'xs'}>{item.id}</Text>
          </Avatar>
        ) : null}
        <VStack flex={1} space={1}>
          <Heading size="xs" fontWeight='normal' w={'full'}>{item.name}</Heading>

          {activeCoupon ? (
            <HStack justifyContent={'flex-start'}>
              <Badge variant={'outline'} colorScheme='primary' rounded={'full'} _text={{fontSize: '9px'}} flexDirection='row' justifyContent={'space-between'} alignItems='center' py={'0.5px'} px={'4px'}>
                {getIcon('mi/local-offer', {color: theme.colors.primary[400], size: 9})} {activeCoupon.name} - {`${activeCoupon.prefix ?? ''}${activeCoupon.amount}${activeCoupon.suffix ?? ''}`}
              </Badge>
            </HStack>
          ) : null}
        </VStack>

        {!isNil(onFavoriteClick) ? (
          <IconButton
            icon={
              <Icon 
                as={MaterialIcons} 
                size={activeCoupon ? 'md' : 'sm'} 
                name={isFavorited ? 'favorite' : 'favorite-outline'}
              />
            }
            colorScheme='primary'
            onPress={() => onFavoriteClick(item)}
            rounded='full'
            variant='ghost'
          />
        ) : null}
      </HStack>

      {item.addressLine && item.addressLine.length > 0 ? (
        <Text fontSize={'xs'} fontWeight='thin' color={theme.colors.gray[600]}>{item.addressLine}</Text>
      ) : null}

      <ActionIconButtons
        id={item.id}
        navigation={navigation}
        logCallback={saveActionLog}
      />
    </VStack>
  );
}

export default memo(MapItem);
