import { HStack, Modal, Skeleton, useTheme, VStack } from 'native-base';
import React, { FC, memo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FlatList } from 'react-native';
import ContactMemberListItem from '../components/ContactMemberListItem';
import ContactMember from '../models/ContactMember.class';
import Item from '../models/Item.class';
import { ActionSheetOptions } from '@expo/react-native-action-sheet'

interface ContactMembersModalProps {
  item?: Item
  isVisible: boolean
  onDismiss: () => void
  showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void;
}

const ContactMembersModal: FC<ContactMembersModalProps> = ({item, isVisible, onDismiss, showActionSheetWithOptions}) => {
  const theme = useTheme()

  const [members, setMembers] = useState<ContactMember[]>()

  useEffect(() => {
    setMembers(undefined)

    if (item && isVisible) {
      (async () => {
        setMembers(await item.fetchMembers())
      })()
    }
  }, [item, isVisible])

  return (
    <Modal isOpen={isVisible} onClose={onDismiss} size={'xl'}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Nomes</Modal.Header>
        <Modal.Body>
          {!members || !Array.isArray(members) ? (
            Array(item?.membersQuantity ?? 1).fill(0).map((_, index) => (
              <VStack key={index} p={2} mx={2} space={2} opacity={1 - index * 3/10} rounded="lg" bgColor={theme.colors.white}>
                <Skeleton.Text />
                <HStack space={2} justifyContent='center' alignItems='center'>
                  {Array(4).fill(0).map((_, index) => (
                    <Skeleton key={index} size={6} rounded={'full'} startColor={theme.colors.primary[800]} />
                  ))}
                </HStack>
              </VStack>
            ))
          ) : (
            <FlatList
              data={members}
              keyExtractor={item => item.id}
              renderItem={({item}) => <ContactMemberListItem member={item} showActionSheetWithOptions={showActionSheetWithOptions} />}
            />
          )}
        </Modal.Body>

      </Modal.Content>
    </Modal>
  );
}

export default memo(ContactMembersModal);
