import Item from "../models/Item.class"

export const sortByOrderASC = (a: any, b: any) => {
  if (a.order < b.order) {
    return -1
  } else if (a.order > b.order) {
    return 1
  } return 0
}

export const sortByOrderDESC = (a: any, b: any) => {
  if (a.order < b.order) {
    return 1
  } else if (a.order > b.order) {
    return -1
  } return 0
}

export const sortByNameASC = (a: any, b: any) => {
  if (a.name < b.name) {
    return -1
  } else if (a.name > b.name) {
    return 1
  } return 0
}

export const sortByNameDESC = (a: any, b: any) => {
  if (a.name < b.name) {
    return 1
  } else if (a.name > b.name) {
    return -1
  } return 0
}

export const sortByTitleASC = (a: any, b: any) => {
  if (a.title < b.title) {
    return -1
  } else if (a.title > b.title) {
    return 1
  } return 0
}

export const sortByTitleDESC = (a: any, b: any) => {
  if (a.title < b.title) {
    return 1
  } else if (a.title > b.title) {
    return -1
  } return 0
}

export const sortItemsByRelevanceDESC = (a: Item, b: Item) => {
  if (a.relevance > b.relevance) {
    return -1
  } else if (a.relevance < b.relevance) {
    return 1
  } else if (a.updatedAt !== b.updatedAt) {
    if (!b.updatedAt) {
      return -1
    } else if (!a.updatedAt) {
      return 1
    } else if (a.updatedAt > b.updatedAt) {
      return -1
    }
    return 1
  }

  return 0
}
