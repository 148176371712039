import { useNavigation } from '@react-navigation/native';
import React, { FC, memo } from 'react';
import { View } from 'react-native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTheme, Button, ScrollView, Heading, Text, VStack, Box } from 'native-base';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { HelpStackParamList } from '../navigators/HelpNavigator';
import { openLink } from '../helpers/link.helper';

const TutorialClaimBusinessHelp: FC = () => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackParamList>>()

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <ScrollView
        width={'full'}
        padding={4}
      >
        <VStack width={'full'} space={4}>
          <Heading color={theme.colors.primary[800]}>Como reivindicar uma empresa ou serviço?</Heading>

          <Box
            backgroundColor={theme.colors.white}
            rounded='md'
            padding={4}
          >
            <VStack space={2}>
              <Heading size='sm' color={theme.colors.primary[800]}>1) Crie sua conta no BrasilAmericano</Heading>
              <Text>
                Todas suas empresas e serviços são gerenciados através de sua conta pessoal no BrasilAmericano.
                Então, antes de prosseguir com o registro de seu negócio, crie sua conta.
              </Text>
              <Button onPress={() => openLink('https://brasilamericano.com/perfil')}>
                Registre-se no BrasilAmericano
              </Button>
            </VStack>
          </Box>

          <Box
            backgroundColor={theme.colors.white}
            rounded='md'
            padding={4}
          >
            <VStack space={2}>
              <Heading size='sm' color={theme.colors.primary[800]}>2) Envie um email para nós</Heading>
              <Text>
                Com o email que utilizou para se cadastrar no BrasilAmericano, envie um email para nós (endereço de email abaixo).
              </Text>
              <Text>
                Preferencialmente, se sua empresa ou serviço tiver um site, utilize um email com o mesmo domínio.
              </Text>
              <Text>
                No corpo do email, nos informe o nome da empresa ou serviço e, se possível, o link da página no BrasilAmericano.
              </Text>
              <Text>
                Seu pedido de reivindicação será avaliado pela nossa equipe e retornaremos o mais breve possível.
              </Text>
              <Button
                variant='link'
                colorScheme='primary'
                onPress={() => openLink('mailto:contato@brasilamericano.com')}
                padding={0}
              >
                contato@brasilamericano.com
              </Button>
            </VStack>
          </Box>

          <Heading textAlign='center' size='xs' color={theme.colors.primary[800]}>Parabéns! Você completou o tutorial de Como reivindicar uma empresa ou serviço!</Heading>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(connectActionSheet(TutorialClaimBusinessHelp));
