export const getSlideSize = (slidesQty: number, totalWidth: number, additionalMargin: boolean = false): number => {
  if (slidesQty <= 0) {
    slidesQty = 1
  }

  let margin = (4 * 4)
  if (additionalMargin) {
    margin *= 2
  }

  // const finalSlidesQty = slidesQty
  const finalSlidesQty = 3

  return (totalWidth - margin - ((finalSlidesQty - 1) * (2 * 4))) / finalSlidesQty
}
