import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import City from '../models/City.class';
import * as Analytics from 'expo-firebase-analytics'
import { CheckIcon, Icon, Select, useTheme } from 'native-base';
import {SimpleLineIcons} from '@expo/vector-icons'
import State from '../models/State.class';

interface CitySelectProps {
  state?: State;
  onChange: (city?: City) => void
  selectedCity?: City
}

const CitySelect: FC<CitySelectProps> = ({onChange, state, selectedCity}) => {
  const theme = useTheme()

  const [cities, setCities] = useState<City[]>([])

  const handleCityChange = useCallback((newCityId?: string) => {
    const newSelectedCity = (cities ?? []).find(city => city.id === newCityId)

    if (
      (!selectedCity && newSelectedCity) 
      || (selectedCity && !newSelectedCity) 
      || (selectedCity && newSelectedCity && selectedCity.id !== newSelectedCity.id)
    ) {
      onChange(newSelectedCity)

      Analytics.logEvent('city_filter', {
        cityId: newCityId,
        cityName: newSelectedCity?.name ?? '',
      })
    }
  }, [cities, selectedCity, onChange])

  useEffect(() => {
    if (!state) {
      setCities([])
    } else {
      ;(async () => setCities(await state.fetchCities()))()
    }
  }, [state])

  return (
    <Select
      variant='rounded'
      selectedValue={selectedCity?.id ?? ''}
      flex={1}
      accessibilityLabel="Filtrar por Cidade"
      placeholder="Filtrar por Cidade"
      onValueChange={handleCityChange}
      _selectedItem={{
        bg: theme.colors.primary[800],
        leftIcon: <CheckIcon size={2} />
      }}
      dropdownIcon={
        <Icon
          size={3}
          color={theme.colors.primary[800]}
          as={SimpleLineIcons}
          name="arrow-down"
          marginRight={4}
        />
      }
    >
      <Select.Item label='Todas Cidades' value='' />
      {cities.map(city => (
        <Select.Item key={city.name} label={city.name} value={city.id} />
      ))}
    </Select>
  );
}

export default memo(CitySelect);
