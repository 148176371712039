import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { collection, CollectionReference, doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import ListItem from '../components/ListItem';
import { sortByNameASC, sortItemsByRelevanceDESC } from '../helpers/sort.helper';
import { Category } from '../models/Category.class';
import Item from '../models/Item.class';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import State from '../models/State.class';
import City from '../models/City.class';
import ContactMembersModal from '../modals/ContactMembersModal';
import useDebounce from '../hooks/useDebounce';
import { isNil } from 'lodash';
import * as Analytics from 'expo-firebase-analytics'
import { HStack, Skeleton, VStack, useTheme, Heading, Button, FlatList, Menu, useToast, Box, Text } from 'native-base';
import SearchBar from '../components/SearchBar';
import { openLink } from '../helpers/link.helper';
import { getIcon } from '../helpers/icon.helper';
import { useMemo } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { HomeStackParamList } from '../navigators/HomeNavigator';
import StateSelect from '../components/StateSelect';
import CitySelect from '../components/CitySelect';
import { connectActionSheet, useActionSheet } from '@expo/react-native-action-sheet';
import { useSelector } from 'react-redux';
import { getFavorites, getUser } from '../redux/auth/selectors';
import useAuth from '../hooks/useAuth';

export type ItemsSortType = 'relevance' | 'name'
export interface ItemsSortOptions {
  relevance: string
  name: string
}

const CategoryDetails: FC = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<HomeStackParamList, 'category'>>()
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>()
  const firestore = getFirestore();
  const toast = useToast()

  const user = useSelector(getUser)
  const favorites = useSelector(getFavorites)

  const {addFavorite, removeFavorite, isItemFavorited} = useAuth()

  const { showActionSheetWithOptions } = useActionSheet();

  const [previousSortType, setPreviousSortType] = useState<ItemsSortType>('relevance')
  const [itemsSortType, setItemsSortType] = useState<ItemsSortType>('relevance')
  const sortOptions = useMemo<ItemsSortOptions>(() => ({
    relevance: 'Mais Relevantes',
    name: 'Ordem Alfabética'
  }), [])

  const [category, setCategory] = useState<Category | null>();
  const [items, setItems] = useState<Item[] | null>()
  const [filteredItems, setFilteredItems] = useState<Item[]>()
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [selectedState, setSelectedState] = useState<State>()
  
  const [selectedCity, setSelectedCity] = useState<City>()

  const [isContactsModalVisible, setContactModalVisibility] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<Item>()

  const { execute: debouncedLogSearch } = useDebounce(300, (category: Category, term: string) => {
    if (!isNil(category) && !isNil(term) && term.length > 0) {
      Analytics.logEvent('category_search', { 
        categoryName: category.name,
        categoryId: category.id,
        term,
      });
    }
  });

  const handleSearchQueryChange = useCallback((query: string) => {
    setSearchQuery(query)

    if (category) {
      debouncedLogSearch(category, query)
    }
  }, [debouncedLogSearch, category]);

  const showContactsModal = useCallback((item: Item) => {
    setSelectedItem(item)
    setContactModalVisibility(true)
  }, [])

  const handleBeTheFirstClick = useCallback(() => {
    openLink('https://admin.brasilamericano.com')
  }, [])

  const handleFavoriteClick = useCallback(async (item: Item) => {
    if (!user) {
      try {
        if (toast) {
          toast.show({
            placement: 'bottom',
            render: () => (
              <Box bg={theme.colors.info[800]} px={4} py={2} rounded="full" mb={4}>
                <Text textAlign='center' color='white'>Faça o login ou crie sua conta para começar à adicionar favoritos!</Text>
              </Box>
            ),
          })
        }
      } catch {}

      (navigation as any).navigate('userPrincipal', {
        name: 'profile'
      })
      return
    }

    if (isItemFavorited(item)) {
      await removeFavorite(item.snapshot.ref)
    } else {
      await addFavorite(item.snapshot.ref)
    }
  }, [user, favorites, isItemFavorited, addFavorite, removeFavorite, navigation, toast])

  useEffect(() => {
    if (category === undefined && firestore && route.params.id) {
      getDoc(
        doc(
          collection(firestore, 'categories') as CollectionReference<Category>,
          route.params.id,
        )).then(async doc => {
          if (!doc.exists()) {
            setCategory(null)
            return
          }

          const fetchedCategory = new Category(doc)

          const categoryItems = await fetchedCategory.fetchItems()

          setCategory(fetchedCategory)

          categoryItems.sort(sortItemsByRelevanceDESC)

          setItems(categoryItems)
        })
    }
  }, [category, firestore, route.params.id])

  useEffect(() => {
    if (category) {
      navigation.setOptions({
        title: `BrasilAmericano | ${category.name}`,
        headerTitle: category.name,
      })
    }
  }, [category, navigation])

  useEffect(() => {
    if (items && itemsSortType !== previousSortType) {
      const newSortedItems = Array.from(items)

      if (itemsSortType === 'relevance') {
        newSortedItems.sort(sortItemsByRelevanceDESC)
      } else {
        newSortedItems.sort(sortByNameASC)
      }

      setItems(newSortedItems)
      setPreviousSortType(itemsSortType)
    }
  }, [items, itemsSortType])

  useEffect(() => {
    if (items) {
      const newFilteredItems = items.reduce((result: Item[], item: Item) => {
        if (
          (
            searchQuery &&
            searchQuery.trim().length > 0 &&
            !item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.addressLine.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          (
            selectedState && 
            item.state &&
            selectedState.acronym !== item.state.acronym
          ) ||
          (
            selectedCity && 
            item.city &&
            selectedCity.name !== item.city.name
          )
        ) {
          return result
        }

        result.push(item)

        return result
      }, [])

      setFilteredItems(newFilteredItems)
    }
  }, [items, searchQuery, selectedState, selectedCity])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <HStack space={2} p={2} justifyContent='center' alignItems='center' w='full'>
        <SearchBar placeholder={`Buscar ${category ? `em ${category.name}` : `na categoria`}`} flex={1} onChange={handleSearchQueryChange} />
      </HStack>

      <HStack flexWrap={'wrap'} justifyContent={'flex-end'} space={[0, 2, 2]} alignItems={'center'} w='full' px={2}>
        <StateSelect onChange={setSelectedState} />

        <CitySelect state={selectedState} onChange={setSelectedCity} />
      </HStack>

      <HStack justifyContent={'flex-end'} w={'full'} alignItems='center' p={2}>
        <Menu
          width='150'
          placement='left'
          trigger={triggeProps => (
            <Button
              size='sm'
              borderWidth={1}
              borderColor={theme.colors.coolGray[200]}
              _text={{
                color: theme.colors.coolGray[600]
              }}
              variant='unstyled'
              rounded='full'
              leftIcon={
                getIcon('mi/sort', {size: 18, color: theme.colors.primary[800]})!
              }
              rightIcon={
                getIcon('sli/arrow-down', {size: 12, color: theme.colors.primary[800]})!
              }
              {...triggeProps}
            >
              {sortOptions[itemsSortType]}
            </Button>
          )}
        >
          {Object.keys(sortOptions).map(sortType => (
            <Menu.Item key={sortType} _text={{fontSize: 'xs'}} onPress={() => setItemsSortType(sortType as ItemsSortType)}>
              {sortOptions[sortType as keyof ItemsSortOptions]}
            </Menu.Item>
          ))}
        </Menu>
      </HStack>

      <ScrollView>
        {!filteredItems ? (
          <VStack space={2} p={2}>
            {Array(4).fill(0).map((_, index) => (
              <HStack key={index} p={2} mx={2} space={2} opacity={1 - index * 3/10} rounded="lg" bgColor={theme.colors.white}>
                <Skeleton size={8} rounded={'full'} />
                <Skeleton height={8} flex={1} rounded={'full'} />
              </HStack>
            ))}
          </VStack>
        ) : filteredItems.length > 0 ? (
          <FlatList
            style={{
              flex: 1,
            }}
            data={filteredItems}
            keyExtractor={(item) => item.id}
            renderItem={({item}) => (
              <ListItem item={item} showContactsModal={showContactsModal} showActionSheetWithOptions={showActionSheetWithOptions} isFavorited={isItemFavorited(item)} onFavoriteClick={handleFavoriteClick} />
            )}
          />
        ) : (
          <VStack alignItems="center" justifyContent={'center'} space={2} my={4} mx={2}>
            <Heading textAlign={'center'} size="xs">Não há nenhum item cadastrado em {category?.name ?? ''} até o momento.</Heading>
            <Button onPress={handleBeTheFirstClick} size={'xs'}>Seja o(a) primeiro(a)!</Button>
          </VStack>
        )}
      </ScrollView>

      <ContactMembersModal isVisible={isContactsModalVisible} onDismiss={() => setContactModalVisibility(false)} item={selectedItem} showActionSheetWithOptions={showActionSheetWithOptions} />
    </View>
  );
}

export default memo(connectActionSheet(CategoryDetails));
