import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../components/AppHeader';
import CategoryDetails from '../views/CategoryDetails';
import Home from '../views/Home';
import ItemPage from '../views/ItemPage';

export type HomeStackParamList = {
  home: undefined;
  category: { id: string };
  item: { id: string };
};

const Stack = createNativeStackNavigator<HomeStackParamList>()

const HomeNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='home' screenOptions={{
      header: (props) => <AppHeader initialScreen='home' {...props} />
    }}>
      <Stack.Screen name="home" component={Home} options={{
        title: 'BrasilAmericano'
      }} />
      <Stack.Screen name="category" component={CategoryDetails} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
      }} />
      <Stack.Screen name="item" component={ItemPage} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
        headerShown: false
      }} />
    </Stack.Navigator>
  )
}

export default memo(HomeNavigator)
