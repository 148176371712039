import React, { FC, memo, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { View, useWindowDimensions, FlatList as RNFlatList } from 'react-native';
import MapView, {Marker, Region} from 'react-native-maps';
import * as Location from 'expo-location'
import { isNil, isUndefined } from 'lodash';
import { Box, FlatList, Heading, HStack, Pressable, Skeleton, useTheme, VStack, Text, useToast } from 'native-base';
import CitySelect from '../components/CitySelect';
import StateSelect from '../components/StateSelect';
import State from '../models/State.class';
import City from '../models/City.class';
import useDebounce from '../hooks/useDebounce';
import * as Analytics from 'expo-firebase-analytics'
import Item from '../models/Item.class';
import useCategories from '../hooks/useCategories';
import { Category } from '../models/Category.class';
import { getIcon } from '../helpers/icon.helper';
import { collection, CollectionReference, endAt, getDocs, getFirestore, orderBy, Query, query, startAt, where } from 'firebase/firestore';
import * as GeoFire from 'geofire-common'
import useStates from '../hooks/useStates';
import { sortItemsByRelevanceDESC } from '../helpers/sort.helper';
import MapItem from '../components/MapItem';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/core';
import ContactMembersModal from '../modals/ContactMembersModal';
import { useActionSheet } from '@expo/react-native-action-sheet'
import { GeoPoint } from '@google-cloud/firestore';
import { itemWidth } from '../config';
import { TabParamsList } from '../navigators/MainNavigator';
import { useSelector } from 'react-redux';
import { getFavorites, getUser } from '../redux/auth/selectors';
import useAuth from '../hooks/useAuth';
import useAnalytics from '../hooks/useAnalytics';

interface LocationAddress {
  country: string
  state: string
  city: string
}

const MapPage: FC = () => {
  const theme = useTheme()
  const firestore = getFirestore()
  const toast = useToast()

  const navigation = useNavigation<BottomTabNavigationProp<TabParamsList>>()

  const { showActionSheetWithOptions } = useActionSheet();

  const { saveItemMapView } = useAnalytics()

  const {width, height} = useWindowDimensions()

  const {states} = useStates()
  const {categories} = useCategories()
  const [selectedCategoryNames, setSelectedCategoryNames] = useState<string[]>([])
  
  const [selectedState, setSelectedState] = useState<State>()
  const [selectedCity, setSelectedCity] = useState<City>()
  const [userAddress, setUserAddress] = useState<LocationAddress>();
  const [userLocation, setUserLocation] = useState<Location.LocationObject>();
  const [initialRegion, setInitialRegion] = useState<Region>()
  const [locationPermissionStatus, setLocationPermissionStatus] = useState<boolean>();
  const mapRef = useRef<MapView | null>(null)
  const itemsListRef = useRef<RNFlatList>(null)

  const [items, setItems] = useState<Item[] | null>()
  const [filteredItems, setFilteredItems] = useState<Item[] | null>()
  const [futureItemOnFocus, setFutureItemOnFocus] = useState<Item>()
  const [itemOnFocus, setItemOnFocus] = useState<Item>()
  const [viewableItems, setViewableItems] = useState<Item[]>([])
  const [isContactsModalVisible, setContactModalVisibility] = useState<boolean>(false)

  const user = useSelector(getUser)
  const favorites = useSelector(getFavorites)

  const {addFavorite, removeFavorite, isItemFavorited} = useAuth()

  const flexDirection = useMemo<'row' | 'column'>(() => {
    itemsListRef.current?.recordInteraction()

    if (width >= height) {
      return 'row'
    }

    return 'column'
  }, [width, height])

  const handleFavoriteClick = useCallback(async (item: Item) => {
    if (!user) {
      try {
        if (toast) {
          toast.show({
            placement: 'bottom',
            render: () => (
              <Box bg={theme.colors.info[800]} px={4} py={2} rounded="full" mb={4}>
                <Text textAlign='center' color='white'>Faça o login ou crie sua conta para começar à adicionar favoritos!</Text>
              </Box>
            ),
          })
        }
      } catch {}

      navigation.navigate('userPrincipal', {
        name: 'profile'
      })
      return
    }

    if (isItemFavorited(item)) {
      await removeFavorite(item.snapshot.ref)
    } else {
      await addFavorite(item.snapshot.ref)
    }
  }, [user, favorites, isItemFavorited, addFavorite, removeFavorite, navigation, toast])

  const navigateTo = useCallback((coords: GeoPoint) => {
    if (mapRef.current) {
      mapRef.current.animateCamera({
        center: coords,
        zoom: 12
      })
    }
  }, [mapRef.current])

  const handleManualStateSelection = useCallback((newState?: State) => {
    if ((!newState && selectedState) || (!selectedState && newState) || (newState && selectedState && newState.id !== selectedState.id)) {
      setSelectedState(newState)
    
      if (newState && newState.coords) {
        navigateTo(newState.coords)
      }
    }
  }, [selectedState, navigateTo])

  const handleManualCitySelection = useCallback((newCity?: City) => {
    if ((!newCity && selectedCity) || (!selectedCity && newCity) || (newCity && selectedCity && newCity.id !== selectedCity.id)) {
      setSelectedCity(newCity)
    
      if (newCity && newCity.coords) {
        navigateTo(newCity.coords)
      }
    }
  }, [selectedCity, navigateTo])

  const showContactsModal = useCallback(() => {
    setContactModalVisibility(true)
  }, [])

  const handleToggleCategory = useCallback((category: Category) => {
    const newSelectedCategoryNames = Array.from(selectedCategoryNames)
    const categoryIndex = newSelectedCategoryNames.findIndex(categoryName => categoryName === category.name)

    if (categoryIndex < 0) {
      newSelectedCategoryNames.push(category.name)
    } else {
      newSelectedCategoryNames.splice(categoryIndex, 1)
    }

    setSelectedCategoryNames(newSelectedCategoryNames)
  }, [selectedCategoryNames])

  const handleRegionChange = useCallback((region: Region) => {
    if (mapRef && mapRef.current) {
      try {
        ;(async () => {
          const boundaries = await mapRef.current!.getMapBoundaries()

          const northEastHash = GeoFire.geohashForLocation([boundaries.northEast.latitude, boundaries.northEast.longitude])

          const southWestHash = GeoFire.geohashForLocation([boundaries.southWest.latitude, boundaries.southWest.longitude])

          let itemsQuery: Query<Item>;

          if (selectedState) {
            itemsQuery = query(
              collection(firestore, 'items') as CollectionReference<Item>,
              where('geoHash', '!=', null),
              where('active', '==', true),
              where('state.acronym', '==', selectedState.acronym),
              orderBy('geoHash', 'asc'),
              startAt(southWestHash),
              endAt(northEastHash)
            )
          } else {
            itemsQuery = query(
              collection(firestore, 'items') as CollectionReference<Item>,
              where('geoHash', '!=', null),
              where('active', '==', true),
              orderBy('geoHash', 'asc'),
              startAt(southWestHash),
              endAt(northEastHash)
            )
          }

          const results = await getDocs(itemsQuery)

          const newItems: Item[] = []

          results.forEach(doc => {
            const item = new Item(doc)

            if (item.coords && item.coords.latitude <= boundaries.northEast.latitude && item.coords.latitude >= boundaries.southWest.latitude && item.coords.longitude <= boundaries.northEast.latitude && item.coords.longitude >= boundaries.southWest.longitude) {
              newItems.push(item)
            }
          })

          newItems.sort(sortItemsByRelevanceDESC)

          setItems(newItems)

          newItems.map(item => saveItemMapView(item, '', selectedCategoryNames))
        })();
      } catch (e) {
        console.error(e)
      }
    }
  }, [mapRef, firestore, selectedState, saveItemMapView])

  const handleItemListViewableItemChange = useCallback(({viewableItems, changed}) => {
    if (changed) {
      setViewableItems(
        viewableItems.filter(
          (viewableItem: any) => viewableItem.isViewable
        ).map(
          (viewableItem: any) => viewableItem.item
        )
      )
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        setLocationPermissionStatus(false)
        return;
      }

      setLocationPermissionStatus(true)

      let location = await Location.getCurrentPositionAsync({});
      setUserLocation(location);
    })();
  }, []);

  useEffect(() => {
    setFilteredItems(undefined)

    if (items && items.length > 0) {
      const newFilteredItems = items.filter(item => {
        if (selectedCity && item.city?.name !== selectedCity.name) {
          return false
        }

        if (selectedCategoryNames.length > 0 && !selectedCategoryNames.includes(item.category.name)) {
          return false
        }

        return true
      })
      
      setFilteredItems(newFilteredItems)
      if (!itemOnFocus || !newFilteredItems.find(item => item.id === itemOnFocus.id)) {
        setViewableItems([])
        setFutureItemOnFocus(undefined)
        setItemOnFocus(newFilteredItems[0])
        try {
          itemsListRef?.current?.scrollToIndex({
            index: 0,
            animated: true
          })
        } catch {}
      }
    }
  }, [items, selectedCategoryNames])

  useEffect(() => {
    if (userLocation) {
      Location.reverseGeocodeAsync(userLocation.coords, {
        useGoogleMaps: true
      }).then(response => {
        const userLocationAddress = response.reduce((result: LocationAddress, current: Location.LocationGeocodedAddress) => {
          if (result.country.length === 0 && current.isoCountryCode && current.isoCountryCode.length > 0) {
            result.country = current.isoCountryCode
          }

          if (result.state.length === 0 && current.region && current.region.length > 0) {
            result.state = current.region
          }

          if (result.city.length === 0 && current.city && current.city.length > 0) {
            result.city = current.city
          }

          return result
        }, {
          country: '',
          state: '',
          city: ''
        })

        setUserAddress(userLocationAddress)
      })
    }
  }, [userLocation])

  useEffect(() => {
    if (userAddress) {
      // INITIAL LOCATION
      if (['United States', 'US'].includes(userAddress.country) && !initialRegion) {
        setInitialRegion({
          latitude: userLocation!.coords.latitude,
          longitude: userLocation!.coords.longitude,
          latitudeDelta: 0,
          longitudeDelta: 0
        })
      }

      // STATE
      if (userAddress.state && userAddress.state.length > 0 && !selectedState) {
        const userState = states?.find(state => [state.name, state.acronym].includes(userAddress.state))

        if (userState) {
          setSelectedState(userState)
          
          // CITY
          if (userAddress.city && userAddress.city.length > 0 && !selectedCity) {
            (async () => {
              await userState.fetchCities()

              const userCity = userState.cities?.find(city => city.name === userAddress.city)

              if (userCity) {
                setSelectedCity(userCity)
              }
            })();
          }
        }
      }
    }
  }, [userAddress, states])

  useEffect(() => {
    Location.setGoogleApiKey('AIzaSyDcMlY9h-CisZRvf_ovm7l5q-LuSLqmW-4')
  }, [])

  useEffect(() => {
    if (viewableItems.length > 0 && (isNil(futureItemOnFocus) || viewableItems[0].id === futureItemOnFocus.id)) {
      setFutureItemOnFocus(undefined)
      setItemOnFocus(viewableItems[0])
    }
  }, [viewableItems, itemOnFocus, futureItemOnFocus])

  useEffect(() => {
    if (itemsListRef.current && filteredItems && filteredItems.length > 0 && futureItemOnFocus && (!itemOnFocus || itemOnFocus.id !== futureItemOnFocus.id)) {
      const index = filteredItems.findIndex(item => item.id === futureItemOnFocus.id)

      if (index >= 0) {
        try {
          itemsListRef.current.scrollToIndex({
            index,
            animated: true
          })
        } catch {}
      }
    }
  }, [itemsListRef.current, filteredItems, futureItemOnFocus])

  useEffect(() => {
    if (!selectedState || !mapRef || !mapRef.current) {
      setItems([])
    }
  }, [mapRef.current, selectedState])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      {isUndefined(initialRegion) || isUndefined(locationPermissionStatus) || isUndefined(userLocation) ? (
        <Skeleton width={'full'} height='full' />
      ) : (
        <Box width={'full'} flex={1} position='relative'>
          <VStack width={'full'} flex={1} alignItems='center' space={2} zIndex={1}>
            <HStack flexWrap={'wrap'} justifyContent={'flex-end'} space={[0, 2, 2]} alignItems={'center'} w='full' px={2} marginTop={4}>
              <StateSelect selectedState={selectedState} onChange={handleManualStateSelection} disableAll={true} />
              <CitySelect state={selectedState} selectedCity={selectedCity} onChange={handleManualCitySelection} />
            </HStack>

            <FlatList
              marginTop={2}
              width={'full'}
              maxHeight={16}
              paddingLeft={2}
              data={categories}
              keyExtractor={(item) => item.id}
              horizontal={true}
              renderItem={({item}) => (
                <Pressable
                  key={item.id}
                  onPress={() => handleToggleCategory(item)}
                  marginRight={2}
                  height={12}
                >
                  <Box size={12} position='relative' rounded={'full'} borderWidth={selectedCategoryNames.includes(item.name) ? 2 : 0} borderStyle='solid' borderColor={theme.colors.primary[800]} backgroundColor='white' alignItems='center' justifyContent='center'>
                    {getIcon(item.icon, {
                      color: theme.colors.primary[800],
                      size: 25,
                    })}
                  </Box>
                </Pressable>
              )}
            />

            {!selectedState ? (
              <Heading textAlign='center' size="xs">{!locationPermissionStatus ? `A geolocalização parece não estar habilitada. Habilite-a nas Configurações do aparelho para visualizar o que há ao seu redor ou selecine o estado desejado.` : `Por favor, selecione o estado desejado.`}</Heading>
            ) : (
              <Box width={'full'} flex={1} flexDirection={flexDirection}>
                <MapView
                  ref={mapRef}
                  style={{
                    width,
                    flex: 2,
                  }}
                  initialRegion={initialRegion}
                  showsMyLocationButton={false}
                  showsPointsOfInterest={false}
                  showsBuildings={false}
                  showsTraffic={false}
                  showsIndoors={false}
                  onRegionChangeComplete={handleRegionChange}
                >
                  {!isNil(filteredItems) ? filteredItems.map(item => (
                    <Marker
                      key={item.id}
                      identifier={item.id}
                      coordinate={item.coords!}
                      opacity={isNil(itemOnFocus) || itemOnFocus.id === item.id ? 1.0 : 0.5}
                      onPress={() => setFutureItemOnFocus(item)}
                    />
                  )) : null}
                </MapView>

                {!isNil(filteredItems) && !isNil(selectedState) ? (
                  <FlatList
                    ref={itemsListRef}
                    padding={4}
                    flex={flexDirection === 'row' ? 1 : 'none'}
                    flexGrow={flexDirection === 'row' ? 1 : 'none'}
                    data={filteredItems}
                    horizontal={flexDirection === 'column'}
                    onViewableItemsChanged={handleItemListViewableItemChange}
                    onScrollToIndexFailed={info => {
                      const wait = new Promise(resolve => setTimeout(resolve, 500));
                      wait.then(() => {
                        itemsListRef.current?.scrollToIndex({ index: info.index, animated: true });
                      });
                    }}
                    viewabilityConfig={{
                      itemVisiblePercentThreshold: 50
                    }}
                    keyExtractor={(item) => item.id}
                    renderItem={({item}) => <Box marginBottom={flexDirection === 'row' ? 2 : 0} marginRight={flexDirection === 'column' ? 2 : 0} width={flexDirection === 'column' ? itemWidth : 'full'}>
                      <MapItem item={item} handleOpenContactsModal={showContactsModal} navigation={navigation} showActionSheetWithOptions={showActionSheetWithOptions} isFavorited={isItemFavorited(item)} onFavoriteClick={handleFavoriteClick} />
                    </Box>}
                  />
                ) : null}
              </Box>
            )}
          </VStack>
        </Box>
      )}

      <ContactMembersModal isVisible={isContactsModalVisible} onDismiss={() => setContactModalVisibility(false)} item={itemOnFocus} showActionSheetWithOptions={showActionSheetWithOptions} />
    </View>
  );
}

export default memo(MapPage);
