import { useNavigation } from '@react-navigation/native';
import React, { FC, memo, useCallback, useState } from 'react';
import { View } from 'react-native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTheme, Button, FlatList, Icon, ScrollView, Heading, Text, VStack } from 'native-base';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { HelpStackParamList } from '../navigators/HelpNavigator';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { openLink } from '../helpers/link.helper';

interface HelpItem {
  name: string
  page: keyof HelpStackParamList
  icon: {
    family: any
    name: string
  }
}

const HelpCenterPage: FC = () => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackParamList>>()

  const [items] = useState<HelpItem[]>([
    {
      name: 'Como registrar sua empresa ou serviço?',
      page: 'createBusiness',
      icon: {
        family: MaterialCommunityIcons,
        name: 'briefcase-plus'
      }
    },
    {
      name: 'Como reivindicar uma empresa ou serviço?',
      page: 'claimBusiness',
      icon: {
        family: MaterialCommunityIcons,
        name: 'flag-variant'
      }
    }
  ])

  const handleHelpItemClick = useCallback((item: HelpItem) => {
    if (navigation) {
      navigation.push(item.page)
    }
  }, [navigation])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <ScrollView
        width={'full'}
        padding={2}
      >
        <FlatList
          width={'full'}
          data={items}
          keyExtractor={item => item.name}
          renderItem={({item}) => (
            <Button
              rounded='full'
              bgColor='white'
              startIcon={
                <Icon as={item.icon.family} name={item.icon.name} marginRight={2} color={theme.colors.primary[800]} />
              }
              onPress={() => handleHelpItemClick(item)}
              justifyContent='flex-start'
              _text={{
                color: theme.colors.black,
              }}
              flexWrap='wrap'
              marginBottom={2}
            >
              {item.name}
            </Button>
          )}
        />

        <VStack
          space={2}
          justifyContent='center'
          alignItems='center'
          marginTop={2}
        >
          <Heading color={theme.colors.primary[800]}>Não encontrou o que precisava?</Heading>
          <Text color={theme.colors.dark[200]}>Entre em contato conosco através do email:</Text>
          <Button
            variant='link'
            colorScheme='primary'
            onPress={() => openLink('mailto:contato@brasilamericano.com')}
            padding={0}
          >
            contato@brasilamericano.com
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(connectActionSheet(HelpCenterPage));
