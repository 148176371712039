import React, { FC, memo, useCallback, useState } from 'react';
import { View, ScrollView } from 'react-native';
import { VStack, FormControl, Input, WarningOutlineIcon, Button } from 'native-base';
import { doc, DocumentReference, getFirestore, setDoc } from 'firebase/firestore';
import { User as FirebaseUser } from 'firebase/auth';
import * as Analytics from 'expo-firebase-analytics'
import { isValidEmail, isValidInput, isValidPassword, isValidSignUpCredentials, PASSWORD_MIN_LENGTH } from '../helpers/validate.helper';
import { User } from '../models/User.class';
import { ProfileAuthState } from './Profile';
import useAuth from '../hooks/useAuth';
import moment from 'moment';

export interface SignInProps {
  changeStage: (state: ProfileAuthState) => void
}

const SignUp: FC<SignInProps> = ({changeStage}) => {
  const firestore = getFirestore()

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  
  const {signUp} = useAuth()

  const goToSignIn = () => {
    changeStage('signin')
  }

  const createProfile = useCallback(async (user: FirebaseUser, firstName: string, lastName: string) => {
    if (firestore && user) {
      return setDoc(doc(firestore, 'users', user.uid) as DocumentReference<Omit<User, 'id' | 'snapshot' | 'fetchFavoriteItems' | 'fetchFavoriteItemRefs'>>, {
        firstName,
        lastName,
        photo: '',
        email: user.email ?? '',
        role: 'user',
        createdAt: moment().utc().toDate(),
      })
    }

    try {
      Analytics.logEvent('users_error', {
        method: 'fetch_users',
        message: 'invalid firestore or user'
      })
    } catch (e) {
      console.error(e)
    }
  }, [firestore])

  const handleSignUpClick = useCallback(async () => {
    setSubmitted(true)
    setLoading(true)

    if (isValidSignUpCredentials({email, password, confirmPassword, firstName, lastName})) {
      try {
        const user = await signUp(email, password)
        if (!user) {
          throw new Error('invalid user')
        }
        await createProfile(user, firstName, lastName)
        changeStage('signin')
      } catch (e) {
        console.error(e)
        setLoading(false)

        try {
          Analytics.logEvent('sign_up_error', {
            method: 'handleSignUpClick',
            email,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
      }
    } else {
      setLoading(false)
    }
  }, [email, password, confirmPassword, signUp, createProfile, firstName, lastName])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <ScrollView style={{
        flex: 1,
        minHeight: '100%',
        padding: 20,
      }}>
        <VStack space={4} alignItems='center'>
          <FormControl isRequired isInvalid={submitted && !isValidInput(firstName, 1)}>
            <VStack>
              <FormControl.Label>Nome</FormControl.Label>
              <Input type="text" variant={'rounded'} value={firstName} onChangeText={text => setFirstName(text)} keyboardType='default' autoCapitalize='words' autoCompleteType="name" />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {`Por favor, insira um nome válido.`}
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={submitted && !isValidInput(lastName, 1)}>
            <VStack>
              <FormControl.Label>Sobrenome</FormControl.Label>
              <Input type="text" variant={'rounded'} value={lastName} onChangeText={text => setLastName(text)} keyboardType='default' autoCapitalize='words' autoCompleteType='name' />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {`Por favor, insira um sobrenome válido.`}
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={submitted && !isValidEmail(email)}>
            <VStack>
              <FormControl.Label>Email</FormControl.Label>
              <Input type="text" variant={'rounded'} placeholder='Email' value={email} onChangeText={text => setEmail(text)} keyboardType='email-address' />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Por favor, insira um email válido.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={submitted && !isValidPassword(password)}>
            <VStack>
              <FormControl.Label>Senha</FormControl.Label>
              <Input type="password" variant={'rounded'} placeholder='Senha' value={password} onChangeText={text => setPassword(text)} />
              <FormControl.HelperText>
                {`A senha deve ter pelo menos ${PASSWORD_MIN_LENGTH} caracteres.`}
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {`Pelo menos ${PASSWORD_MIN_LENGTH} caracteres são obrigatórios.`}
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={password !== confirmPassword}>
            <VStack>
              <FormControl.Label>Confirmar Senha</FormControl.Label>
              <Input type="password" variant={'rounded'} placeholder='Confirmação de Senha' value={confirmPassword} onChangeText={text => setConfirmPassword(text)} />
              <FormControl.HelperText>
                Insira a senha novamente.
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                A senhas devem ser idênticas.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>
          
          <Button isLoading={loading} isLoadingText='Registrando' width={'full'} rounded='full' onPress={handleSignUpClick}>
            Registrar
          </Button>

          <Button width={'full'} rounded='full' variant='link' onPress={goToSignIn} disabled={loading}>
            Já possui uma conta? Entre aqui
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(SignUp);
