import { Button, HStack, Icon, IconButton, useTheme, Text } from "native-base"
import React, { FC, memo, useCallback } from "react"
import { Platform } from "react-native"
import { getIcon } from "../helpers/icon.helper"
import { openLink } from "../helpers/link.helper"
import { Feather } from '@expo/vector-icons'
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { ItemCoupon } from "../models/Item.class"
import { HomeStackParamList } from "../navigators/HomeNavigator"
import { ActionSheetOptions } from '@expo/react-native-action-sheet'
import { isNil } from "lodash"
import { openMaps } from "../helpers/maps.helper"

interface IActionIcon {
  name: string
  iconName: string,
  action: () => void,
  condition: boolean
}

interface ActionIconButtonsProps {
  website?: string | null
  email?: string | null
  phone?: string | null
  sms?: string | null
  whatsapp?: string | null
  instagram?: string | null
  facebook?: string | null
  youtube?: string | null
  addressLine?: string | null
  membersAction?: () => void;
  logCallback?: (actionName: string) => void
  individualPage?: boolean
  id?: string
  activeCoupon?: ItemCoupon
  navigation?: NativeStackNavigationProp<HomeStackParamList, keyof HomeStackParamList>
  showActionSheetWithOptions?: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void;
  showLabels?: boolean
  noMarginBottom?: boolean
}

const ActionIconButtons: FC<ActionIconButtonsProps> = ({
  website,
  email,
  phone,
  sms,
  whatsapp,
  instagram,
  facebook,
  youtube,
  membersAction,
  addressLine,
  logCallback,
  id,
  activeCoupon,
  navigation,
  showActionSheetWithOptions,
  showLabels,
  noMarginBottom
}) => {
  const theme = useTheme()

  const couponMessage = !!activeCoupon ? `\nEste é meu cupom: ${activeCoupon.name}` : '\n'

  const predefinedSubject = encodeURIComponent('Mais Informações')
  const predefinedMessage = encodeURIComponent(`Olá! Tudo bem?\n\nEncontrei seu contato no https://brasilamericano.com/ ${couponMessage}Está podendo falar?\n\n`)
  const predefinedEmail= encodeURIComponent(`Olá! Tudo bem?\n\nEncontrei seu contato no https://brasilamericano.com/ ${couponMessage}Gostaria de saber mais sobre vocês.\n\n`)
  const openPage = useCallback(() => {
    if (id && navigation) {
      if (logCallback) logCallback('page')
      navigation.push('item', { id })
    }
  }, [id, logCallback, navigation])

  const actionIcons: IActionIcon[] = [
    {
      name: 'Website',
      iconName: 'mci/web',
      action: () => {
        if (logCallback) logCallback('website')
        openLink(website)
      },
      condition: !!website && website.length > 0
    },
    {
      name: 'Email',
      iconName: 'mci/email',
      action: () => {
        if (logCallback) logCallback('email')
        openLink(email ? `mailto:${email}?subject=${predefinedSubject}&body=${predefinedEmail}` : '')
      },
      condition: !!email && email.length > 0
    },
    {
      name: 'Telefone',
      iconName: 'fa/phone',
      action: () => {
        if (logCallback) logCallback('phone')
        openLink(phone ? `tel:${phone}` : '')
      },
      condition: !!phone && phone.length > 0
    },
    {
      name: 'SMS',
      iconName: 'fa5/sms',
      action: () => {
        if (logCallback) logCallback('sms')
        openLink(sms ? `sms:${sms}&body=${predefinedMessage}` : '')
      },
      condition: !!sms && sms.length > 0
    },
    {
      name: 'WhatsApp',
      iconName: 'mci/whatsapp',
      action: () => {
        if (logCallback) logCallback('whatsapp')
        openLink(whatsapp ? `https://api.whatsapp.com/send?phone=${whatsapp}&text=${predefinedMessage}` : '')
      },
      condition: !!whatsapp && whatsapp.length > 0
    },
    {
      name: 'Instagram',
      iconName: 'mci/instagram',
      action: () => {
        if (logCallback) logCallback('instagram')
        openLink(instagram ? `https://www.instagram.com/${instagram}` : '')
      },
      condition: !!instagram && instagram.length > 0
    },
    {
      name: 'Facebook',
      iconName: 'etp/facebook',
      action: () => {
        if (logCallback) logCallback('facebook')
        openLink(facebook ? `https://www.facebook.com/${facebook}` : '')
      },
      condition: !!facebook && facebook.length > 0
    },
    {
      name: 'YouTube',
      iconName: 'ion/logo-youtube',
      action: () => {
        if (logCallback) logCallback('youtube')
        openLink(youtube ? `https://www.youtube.com/${youtube}` : '')
      },
      condition: !!youtube && youtube.length > 0
    },
    {
      name: 'Equipe',
      iconName: 'mi/groups',
      action: () => {
        if (logCallback) logCallback('members')
        membersAction!()
      },
      condition: !!membersAction
    },
    {
      name: 'Como chegar?',
      iconName: 'mci/map-marker',
      action: () => {
        if (logCallback) logCallback('directions')

        if (!showActionSheetWithOptions) {
          openMaps(addressLine, 'os')
          return
        }

        showActionSheetWithOptions({
          options: (Platform.OS === 'ios' ? ['Abrir no Maps'] : ['Abrir no Google Maps']).concat(['Abrir no Waze', 'Abrir no Navegador', 'Cancelar']),
          cancelButtonIndex: 3,
        }, (buttonIndex => {
          if (!isNil(buttonIndex) && buttonIndex < 3) {
            openMaps(addressLine, ['os', 'waze', 'browser'][buttonIndex] as any)
          }
        }))
      },
      condition: !!addressLine && addressLine.length > 0 
    }
  ].filter(actionIcon => actionIcon.condition)

  const marginBottom = !noMarginBottom ? 2 : 0

  if (actionIcons.length === 0 && !id) {
    return null
  }

  return (
    <HStack space={2} justifyContent='center' alignItems='center' flexWrap={'wrap'}>
      {id ? (
        <Button
          variant={'solid'}
          rounded='full'
          onPress={openPage}
          marginBottom={marginBottom}
          rightIcon={<Icon name='external-link' as={Feather} color={theme.colors.white} size={4} />}
          size={'xs'}
        >
          Página
        </Button>
      ) : null}

      {actionIcons.map(actionIcon => {
        if (!showLabels) {
          return (
            <IconButton
              variant={'solid'}
              rounded='full'
              bgColor={theme.colors.primary[800]}
              key={actionIcon.name}
              onPress={actionIcon.action}
              marginBottom={marginBottom}
              icon={getIcon(actionIcon.iconName, {size: 16, color: theme.colors.white}) ?? undefined}
            />
          )
        }

        return (
          <Button
            variant={'solid'}
            rounded='full'
            bgColor={theme.colors.primary[800]}
            key={actionIcon.name}
            onPress={actionIcon.action}
            marginBottom={marginBottom}
            leftIcon={getIcon(actionIcon.iconName, {size: 16, color: theme.colors.white}) ?? undefined}
            size={'xs'}
          >
            {actionIcon.name}
          </Button>
        )
      })}
    </HStack>
  )
}

export default memo(ActionIconButtons)
