import { isNil, isNull } from 'lodash';
import Item from './../../models/Item.class';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import authState, { AuthState } from './state';
import { User as FirebaseUser} from 'firebase/auth'
import { User } from '../../models/User.class';
import { DocumentReference } from 'firebase/firestore';

export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    setUser(state: AuthState, action: PayloadAction<FirebaseUser | null | undefined>) {
      state.user = action.payload

      if (isNil(action.payload)) {
        state.userData = action.payload
      } else if (isNull(state.userData) || (!isNil(state.userData) && action.payload.uid !== state.userData.id)) {
        state.userData = undefined
      }
    },
    setUserData(state: AuthState, action: PayloadAction<User | null | undefined>) {
      state.userData = action.payload
    },
    setFavorites(state: AuthState, action: PayloadAction<DocumentReference<Item>[] | undefined>) {
      state.favorites = action.payload
    }
  },
});

export const {setUser, setUserData, setFavorites} = authSlice.actions;
export default authSlice.reducer;
