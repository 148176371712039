import { collection, CollectionReference, DocumentReference, DocumentSnapshot, GeoPoint, getDocs, orderBy, query } from "firebase/firestore";
import City from "./City.class";
import { FirestoreSnapshot } from "./FirestoreSnapshot.class";
import * as Analytics from 'expo-firebase-analytics'

export interface StateRef {
  ref: DocumentReference<State>
  name: string
  acronym: string
}

export default class State extends FirestoreSnapshot<State> {
  name: string
  acronym: string
  coords?: GeoPoint | null
  geoHash?: string | null

  cities?: City[]

  constructor(doc: DocumentSnapshot<State>) {
    super(doc)

    const data = doc.data()!

    this.name = data.name
    this.acronym = data.acronym
    this.coords = data.coords
    this.geoHash = data.geoHash
  }

  async fetchCities(): Promise<City[]> {
    if (this.cities && this.cities.length > 0) {
      return this.cities
    }

    try {
      const result = await getDocs(query(collection(this.snapshot.ref.firestore, `${this.snapshot.ref.path}/cities`) as CollectionReference<City>, orderBy('name', 'asc')))

      const cities: City[] = []
      result.forEach(doc => cities.push(new City(doc)))

      this.cities = cities
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('state_error', {
          method: 'fetchCities',
          id: this.id,
          message: (e as Error).name ?? (e as Error).message
        })
      } catch (e) {
        console.error(e)
      }
    }
    finally {
      return this.cities ?? []
    }
  }
}
