import { Category, CategoryRef } from './../models/Category.class';
import { CollectionReference, WithFieldValue } from 'firebase/firestore';
import { collection } from 'firebase/firestore';
import { addDoc, getFirestore } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import Item from '../models/Item.class';
import { getUserData } from '../redux/auth/selectors';
import * as Network from 'expo-network';
import OpenDetails from '../models/OpenDetails.class';
import moment from 'moment'
import { useNavigationState } from '@react-navigation/native';
import ButtonClick from '../models/ButtonClick.class';
import MapView from '../models/MapView.class';
import PageView from '../models/PageView.class';
import SearchView from '../models/SearchView.class';

const useAnalytics = () => {
  const firestore = getFirestore()

  const userData = useSelector(getUserData)
  const navigationState = useNavigationState(state => state)

  const getIP = useCallback(async (): Promise<string | null> => {
    try {
      const ip = await Network.getIpAddressAsync()
      return ip
    } catch {
      return null
    }
  }, [])

  const saveItemOpenDetails = useCallback(async (item: Item, category?: Category) => {
    if (firestore) {
      addDoc(collection(firestore, item.snapshot.ref.path, 'openDetails') as CollectionReference<OpenDetails>, {
        ip: await getIP(),
        createdAt: moment().utc().toDate(),
        userRef: userData?.snapshot.ref ?? null,
        categoryRef: category ? {
          name: category.name,
          ref: category.snapshot.ref
        } : null,
        itemRef: {
          ref: item.snapshot.ref
        },
        url: navigationState.routeNames[navigationState.index],
        avatarHighlight: false,
      } as WithFieldValue<OpenDetails>)
    }
  }, [getIP, userData, firestore])

  const saveItemButtonClick = useCallback(async (item: Item, button: string, category?: Category) => {
    if (firestore) {
      addDoc(collection(firestore, item.snapshot.ref.path, 'buttonClicks') as CollectionReference<ButtonClick>, {
        ip: await getIP(),
        createdAt: moment().utc().toDate(),
        userRef: userData?.snapshot.ref ?? null,
        categoryRef: category ? {
          name: category.name,
          ref: category.snapshot.ref
        } : null,
        itemRef: {
          ref: item.snapshot.ref
        },
        url: navigationState.routeNames[navigationState.index],
        avatarHighlight: false,
        button,
      } as WithFieldValue<ButtonClick>)
    }
  }, [getIP, userData, firestore])

  const saveItemMapView = useCallback(async (item: Item, searchText: string = '', categoryFilters: string[] = []) => {
    if (firestore) {
      addDoc(collection(firestore, item.snapshot.ref.path, 'mapViews') as CollectionReference<MapView>, {
        ip: await getIP(),
        createdAt: moment().utc().toDate(),
        userRef: userData?.snapshot.ref ?? null,
        itemRef: {
          ref: item.snapshot.ref
        },
        url: navigationState.routeNames[navigationState.index],
        avatarHighlight: false,
        searchText,
        categoryFilters,
      } as WithFieldValue<MapView>)
    }
  }, [getIP, userData, firestore])

  const saveItemPageView = useCallback(async (item: Item, origin, category?: Category, avatarHighlight: boolean = false) => {
    if (firestore) {
      addDoc(collection(firestore, item.snapshot.ref.path, 'pageViews') as CollectionReference<PageView>, {
        ip: await getIP(),
        createdAt: moment().utc().toDate(),
        userRef: userData?.snapshot.ref ?? null,
        itemRef: {
          ref: item.snapshot.ref
        },
        categoryRef: category ? {
          name: category.name,
          ref: category.snapshot.ref
        } : null,
        origin,
        url: navigationState.routeNames[navigationState.index],
        avatarHighlight,
      } as WithFieldValue<PageView>)
    }
  }, [getIP, userData, firestore])

  const saveItemSearchView = useCallback(async (item: Item, searchText: string) => {
    if (firestore) {
      addDoc(collection(firestore, item.snapshot.ref.path, 'searchViews') as CollectionReference<SearchView>, {
        ip: await getIP(),
        createdAt: moment().utc().toDate(),
        userRef: userData?.snapshot.ref ?? null,
        itemRef: {
          ref: item.snapshot.ref
        },
        url: navigationState.routeNames[navigationState.index],
        avatarHighlight: false,
        searchText
      } as WithFieldValue<SearchView>)
    }
  }, [getIP, userData, firestore])
  
  return {
    saveItemOpenDetails,
    saveItemButtonClick,
    saveItemMapView,
    saveItemPageView,
    saveItemSearchView
  }
}

export default useAnalytics
