import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import State from '../models/State.class';
import * as Analytics from 'expo-firebase-analytics'
import { CheckIcon, Icon, Select, useTheme } from 'native-base';
import {SimpleLineIcons} from '@expo/vector-icons'
import useStates from '../hooks/useStates';

interface StateSelectProps {
  onChange: (state?: State) => void
  selectedState?: State
  disableAll?: boolean
}

const StateSelect: FC<StateSelectProps> = ({onChange, selectedState, disableAll}) => {
  const theme = useTheme()
  const {states} = useStates()

  const handleStateChange = useCallback((newStateId?: string) => {
    const newSelectedState = (states ?? []).find(state => state.id === newStateId)

    if (
      (!selectedState && newSelectedState) 
      || (selectedState && !newSelectedState) 
      || (selectedState && newSelectedState && selectedState.id !== newSelectedState.id)
    ) {
      onChange(newSelectedState)

      Analytics.logEvent('state_filter', {
        stateId: newStateId,
        stateName: newSelectedState?.name ?? '',
      })
    }
  }, [states, selectedState, onChange])

  return (
    <Select
      variant='rounded'
      selectedValue={selectedState?.id ?? ''}
      flex={1}
      accessibilityLabel="Filtrar por Estado"
      placeholder="Filtrar por Estado"
      onValueChange={handleStateChange}
      _selectedItem={{
        bg: theme.colors.primary[800],
        leftIcon: <CheckIcon size={2} />
      }}
      dropdownIcon={
        <Icon
          size={3}
          color={theme.colors.primary[800]}
          as={SimpleLineIcons}
          name="arrow-down"
          marginRight={4}
        />
      }
    >
      <Select.Item label={disableAll ? 'Selectione um estado' : 'Todos Estados'} value='' />

      {(states ?? []).map(state => (
        <Select.Item key={state.name} label={state.name} value={state.id} />
      ))}
    </Select>
  );
}

export default memo(StateSelect);
