import 'react-native-gesture-handler'
import { StatusBar } from 'expo-status-bar';
import { SafeAreaView } from 'react-native';
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { extendTheme, NativeBaseProvider } from 'native-base'
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { Provider } from 'react-redux';
import store from './redux';
import React from 'react';
import SessionManager from './components/SessionManager';
import RootNavigator from './navigators/RootNavigator';

export default function App() {
  const app = initializeApp({
    apiKey: "AIzaSyDwoXe7b39KMI9yfJdeKtglINHdBpf3OVQ",
    authDomain: "brasilamericano-88713.firebaseapp.com",
    projectId: "brasilamericano-88713",
    storageBucket: "brasilamericano-88713.appspot.com",
    messagingSenderId: "104906222091",
    appId: "1:104906222091:web:696be567ef16f72004350e",
    measurementId: "G-YGLLCNGWY4"
  })

  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LdwsiohAAAAABSakr3Eoy1HzSo_-r8-M_W2ahtm'),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });

  const theme = extendTheme({
    colors: {
      primary: {
        50: '#f0fdfa',
        100: '#ccfbf1',
        200: '#99f6e4',
        300: '#5eead4',
        400: '#2dd4bf',
        500: '#14b8a6',
        600: '#0d9488',
        700: '#0f766e',
        800: '#115e59',
        900: '#134e4a',
      }
    }
  })

  return (
    <Provider store={store}>
      <ActionSheetProvider>
        <NativeBaseProvider theme={theme}>
          <SafeAreaView style={{
            flex: 1,
            overflow: 'hidden'
          }}>
            <StatusBar style="auto" />
            <SessionManager /> 
            <RootNavigator />
          </SafeAreaView>
        </NativeBaseProvider>
      </ActionSheetProvider>
    </Provider>
  );
}
