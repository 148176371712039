import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FC, memo, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import useAuth from '../hooks/useAuth';
import { setUser } from '../redux/auth';

const SessionManager: FC = () => {
  const auth = getAuth();
  const dispatch = useDispatch()

  useAuth()

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      dispatch(setUser(user))
    })
  }, [auth, dispatch])

  return null;
}

export default memo(SessionManager);
