import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { Category } from '../models/Category.class';
import { getFirestore, getDocs, collection, query, where, CollectionReference } from 'firebase/firestore'
import { sortItemsByRelevanceDESC } from '../helpers/sort.helper';
import CategoryListItem from '../components/CategoryListItem';

import { isNil } from 'lodash';
import { Box, Heading, HStack, Skeleton, useTheme, useToast, VStack, Text } from 'native-base';
import SearchBar from '../components/SearchBar';
import * as Analytics from 'expo-firebase-analytics'
import useDebounce from '../hooks/useDebounce';
import Item from '../models/Item.class';
import City from '../models/City.class';
import State from '../models/State.class';
import ListItem from '../components/ListItem';
import ContactMembersModal from '../modals/ContactMembersModal';

import StateSelect from '../components/StateSelect';
import CitySelect from '../components/CitySelect';
import useCategories from '../hooks/useCategories';

import { useActionSheet } from '@expo/react-native-action-sheet'
import { useNavigation } from '@react-navigation/native';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { TabParamsList } from '../navigators/MainNavigator';
import { useSelector } from 'react-redux';
import { getFavorites, getUser } from '../redux/auth/selectors';
import useAuth from '../hooks/useAuth';
import useAnalytics from '../hooks/useAnalytics';

const Search: FC = () => {
  const theme = useTheme()

  const navigation = useNavigation<BottomTabNavigationProp<TabParamsList>>()

  const firestore = getFirestore()

  const toast = useToast()

  const { showActionSheetWithOptions } = useActionSheet();

  const { saveItemSearchView } = useAnalytics()

  const [selectedState, setSelectedState] = useState<State>()
  
  const [selectedCity, setSelectedCity] = useState<City>()

  const [isContactsModalVisible, setContactModalVisibility] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<Item>()
  
  const {categories} = useCategories()
  const [ filteredCategories, setFilteredCategories ] = useState<Category[]>();

  const [items, setItems] = useState<Item[] | null>()
  const [filteredItems, setFilteredItems] = useState<Item[] | null>()

  const [ searchQuery, setSearchQuery ] = useState('');

  const user = useSelector(getUser)
  const favorites = useSelector(getFavorites)

  const {addFavorite, removeFavorite, isItemFavorited} = useAuth()

  const { execute: debouncedSearch } = useDebounce(300, (term: string) => {
    if (!isNil(term)) {
      setSearchQuery(term.trim().toLowerCase())
      Analytics.logEvent('home_search', { term: term });
    }
  });

  const handleFavoriteClick = useCallback(async (item: Item) => {
    if (!user) {
      try {
        if (toast) {
          toast.show({
            placement: 'bottom',
            render: () => (
              <Box bg={theme.colors.info[800]} px={4} py={2} rounded="full" mb={4}>
                <Text textAlign='center' color='white'>Faça o login ou crie sua conta para começar à adicionar favoritos!</Text>
              </Box>
            ),
          })
        }
      } catch {}

      navigation.navigate('userPrincipal', {
        name: 'profile'
      })
      return
    }

    if (isItemFavorited(item)) {
      await removeFavorite(item.snapshot.ref)
    } else {
      await addFavorite(item.snapshot.ref)
    }
  }, [user, favorites, isItemFavorited, addFavorite, removeFavorite, navigation, toast])

  const handleSearchQueryChange = useCallback((query: string) => {
    debouncedSearch(query)
  }, [debouncedSearch])

  const showContactsModal = useCallback((item: Item) => {
    setSelectedItem(item)
    setContactModalVisibility(true)
  }, [])

  useEffect(() => {
    if (categories === undefined) {
      setFilteredCategories(undefined)
      return
    }

    if (searchQuery.length < 3) {
      setFilteredCategories([])
      return
    }

    setFilteredCategories(categories.filter(category => category.name.toLowerCase().trim().includes(searchQuery.toLowerCase().trim())))
  }, [categories, searchQuery])

  useEffect(() => {
    setFilteredItems(undefined)

    if (!searchQuery || searchQuery.length < 3) {
      setItems(undefined)

      return
    }

    ;(async () => {
      try {
        const result = await getDocs(
          query(
            collection(firestore, "items") as CollectionReference<Item>, 
            where('active', '==', true), 
            where('nameLowerCase', '>=', searchQuery), 
            where('nameLowerCase', '<=', `${searchQuery}\uf8ff`)
          )
        )
  
        const newItems: Item[] = []
  
        result.docs.forEach(doc => newItems.push(new Item(doc)))
  
        newItems.sort(sortItemsByRelevanceDESC)
  
        setItems(newItems)

        newItems.map(item => saveItemSearchView(item, searchQuery))
      } catch (e) {
        console.error(e)
        
        setItems([])

        try {
          Analytics.logEvent('search_error', {
            method: 'searchItems',
            message: (e as Error).name ?? (e as Error).message
          })
        } catch (e) {
          console.error(e)
        }
      }
    })()
  }, [firestore, searchQuery, saveItemSearchView])

  useEffect(() => {
    if (!items || items.length === 0 || !selectedState) {
      setFilteredItems(items)
      return
    }

    setFilteredItems(items.filter(item => item.state?.acronym === selectedState.acronym && (!selectedCity || (selectedCity && item.city?.name === selectedCity.name))))
  }, [items, selectedCity, selectedState])

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <Box p={2} backgroundColor='white'>
        <SearchBar
          placeholder='Buscar'
          bgColor={'white'}
          onChange={handleSearchQueryChange}
        />
      </Box>

      <HStack flexWrap={'wrap'} justifyContent={'flex-end'} space={[0, 2, 2]} alignItems={'center'} w='full' marginTop={2} px={2}>
        <StateSelect onChange={setSelectedState} />
        
        <CitySelect state={selectedState} onChange={setSelectedCity} />
      </HStack>

      {(searchQuery.length >= 3 && filteredCategories && filteredCategories.length === 0 && filteredItems && filteredItems.length === 0) ? (
        <VStack alignItems="center" justifyContent={'center'} space={2} my={4} mx={2}>
          <Heading textAlign={'center'} size="xs">Não há resultados para sua busca.</Heading>
        </VStack>
      ) : null}

      {filteredCategories && filteredCategories.length > 0 ? (
        <FlatList
          data={filteredCategories}
          keyExtractor={item => item.id}
          style={{
            paddingHorizontal: 10,
            paddingVertical: 10,
          }}
          renderItem={({item}) => <CategoryListItem category={item} />}
        />
      ) : null}

      {searchQuery.length >= 3 && !filteredItems ? (
        <VStack space={2} p={2}>
          {Array(4).fill(0).map((_, index) => (
            <HStack key={index} p={2} mx={2} space={2} opacity={1 - index * 3/10} rounded="lg" bgColor={theme.colors.white}>
              <Skeleton size={8} rounded={'full'} />
              <Skeleton height={8} flex={1} rounded={'full'} />
            </HStack>
          ))}
        </VStack>
      ) : filteredItems && filteredItems.length > 0 ? (
        <FlatList
          style={{
            flex: 1,
            marginTop: 10,
          }}
          data={items}
          keyExtractor={(item) => item.id}
          renderItem={({item}) => (
            <ListItem item={item} showContactsModal={showContactsModal} showActionSheetWithOptions={showActionSheetWithOptions} isFavorited={isItemFavorited(item)} onFavoriteClick={handleFavoriteClick} />
          )}
        />
      ) : null}

      <ContactMembersModal isVisible={isContactsModalVisible} onDismiss={() => setContactModalVisibility(false)} item={selectedItem} showActionSheetWithOptions={showActionSheetWithOptions} />
    </View>
  );
}

export default memo(Search);
