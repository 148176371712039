import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../components/AppHeader';
import HelpCenter from '../views/HelpCenter';
import TutorialClaimBusinessHelp from '../views/TutorialClaimBusinessHelp';
import TutorialCreateBusinessHelp from '../views/TutorialCreateBusinessHelp';

export type HelpStackParamList = {
  help: undefined;
  createBusiness: undefined
  claimBusiness: undefined
};

const Stack = createNativeStackNavigator<HelpStackParamList>()

const HelpNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='help' screenOptions={{
      header: (props) => <AppHeader initialScreen='help' {...props} hideRightSide={true} />,
      headerTitle: 'Central de Ajuda'
    }}>
      <Stack.Screen name="help" component={HelpCenter} options={{
        title: 'BrasilAmericano | Central de Ajuda',
      }} />
      <Stack.Screen name='createBusiness' component={TutorialCreateBusinessHelp} options={{
        title: 'BrasilAmericano | Como registrar sua empresa ou serviço?'
      }} /><Stack.Screen name='claimBusiness' component={TutorialClaimBusinessHelp} options={{
        title: 'BrasilAmericano | Como reivindicar uma empresa (ou serviço)?'
      }} />
    </Stack.Navigator>
  )
}

export default memo(HelpNavigator)
