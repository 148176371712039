import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { collection, CollectionReference, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import { isNil } from 'lodash';
import { Avatar, Heading, HStack, Skeleton, useTheme, VStack, Text, Pressable, Box } from 'native-base';
import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import Item from '../models/Item.class';
import { getIcon } from '../helpers/icon.helper';
import { HomeStackParamList } from '../navigators/HomeNavigator';
import useAnalytics from '../hooks/useAnalytics';

const MoreRelevantItems: FC = () => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>()
  const firestore = getFirestore()

  const { saveItemButtonClick } = useAnalytics()

  const [ items, setItems ] = useState<Item[]>();

  const handleClick = useCallback((item: Item) => {
    saveItemButtonClick(item, 'Home_MoreRelevantItems')
    navigation.push('item', { id: item.id })
  }, [navigation, saveItemButtonClick])

  useEffect(() => {
    if (firestore && isNil(items)) {
      getDocs(query(collection(firestore, 'items') as CollectionReference<Item>, where('relevance', '>=', 0), where('active', '==', true), orderBy('relevance', 'desc'), limit(20))).then(result => {
        const newItems: Item[] = []
        result.forEach(doc => newItems.push(new Item(doc)))
        setItems(newItems)
      })
    }
  }, [firestore])

  const mainColor = theme.colors.primary[800]

  if (isNil(items)) {
    return (
      <HStack space={2} alignItems='center' px={2}>
        {Array(4).fill(0).map((_, index) => (
          <Skeleton key={index} size={12} rounded={'full'} opacity={1 - index * 3/10} />
        ))}
      </HStack>
    )
  }

  if (items.length === 0) {
    return null
  }

  return (
    <VStack space={2} px={4} my={2}>
      <Heading size={'xs'} fontWeight='400'>Top 20 - Mais Relevantes</Heading>

      <ScrollView
        horizontal={true}
      >
        <HStack space={2}>
          {items.map(item => {
            const activeCoupon = item.coupons && item.coupons.length > 0 ? (item.coupons?.find(coupon => coupon.active && !coupon.deletedAt) ?? undefined) : undefined

            return (
              <Pressable
                key={item.id}
                onPress={() => handleClick(item)}
              >
                <Box position='relative'>
                  <Avatar
                    source={{ uri: item.logo! }}
                    bgColor={theme.colors.white}
                    borderWidth={activeCoupon ? 4 : 1}
                    borderColor={mainColor}
                    size='md'
                  >
                    <Text p={2} color={mainColor} numberOfLines={1} ellipsizeMode='tail' isTruncated={true} fontSize={'xs'}>{item.id}</Text>
                  </Avatar>
                  {activeCoupon ? (
                    <Box position={'absolute'} bottom={0} right={0} bgColor={mainColor} rounded='full' h={4} w={4} justifyContent='center' alignItems={'center'}>
                      {getIcon('mi/local-offer', {color: theme.colors.white, size: 9})}
                    </Box>
                  ) : null}
                </Box>
              </Pressable>
            )
          })}
        </HStack>
      </ScrollView>
    </VStack>
  );
}

export default memo(MoreRelevantItems);
