import { useNavigation } from '@react-navigation/native';
import React, { FC, memo } from 'react';
import { View } from 'react-native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTheme, Button, ScrollView, Heading, Text, VStack, Box } from 'native-base';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { HelpStackParamList } from '../navigators/HelpNavigator';
import { openLink } from '../helpers/link.helper';

const TutorialCreateBusinessHelp: FC = () => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackParamList>>()

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden'
      }}
    >
      <ScrollView
        width={'full'}
        padding={4}
      >
        <VStack width={'full'} space={4}>
          <Heading color={theme.colors.primary[800]}>Como registrar sua empresa ou serviço?</Heading>

          <Box
            backgroundColor={theme.colors.white}
            rounded='md'
            padding={4}
          >
            <VStack space={2}>
              <Heading size='sm' color={theme.colors.primary[800]}>1) Crie sua conta no BrasilAmericano</Heading>
              <Text>
                Todas suas empresas e serviços são gerenciados através de sua conta pessoal no BrasilAmericano.
                Então, antes de prosseguir com o registro de seu negócio, crie sua conta.
              </Text>
              <Button onPress={() => openLink('https://brasilamericano.com/perfil')}>
                Registre-se no BrasilAmericano
              </Button>
            </VStack>
          </Box>

          <Box
            backgroundColor={theme.colors.white}
            rounded='md'
            padding={4}
          >
            <VStack space={2}>
              <Heading size='sm' color={theme.colors.primary[800]}>2) Acesse seu Portal do Empreendedor</Heading>
              <Text>
                No Portal do Empreededor, você encontra as ferramentas necessárias para gerenciar seu negócio.
                É uma ferramenta gratuita, simples e intuitiva.
                Além dos dados simples como nome e descrição, você também poderá selecionar imagens (opcional) para deixar a página de seu negócio ainda mais rica e chamativa!
              </Text>
              <Text bold={true}>Caso o Portal do Empreendedor solicite um login e senha, utilize o mesmo criado na etapa acima.</Text>
              <Button onPress={() => openLink('https://admin.brasilamericano.com')}>
                Acesse seu Portal do Empreendedor
              </Button>
            </VStack>
          </Box>

          <Box
            backgroundColor={theme.colors.white}
            rounded='md'
            padding={4}
          >
            <VStack space={2}>
              <Heading size='sm' color={theme.colors.primary[800]}>3) Clique no botão verde redondo com o sinal de "+"</Heading>
              <Text>
                O botão de cadastrar empresas e/ou serviços é o botão verde redondo com sinal de "+" no canto inferior direito do Portal do Empreendedor.
                Através dele, você acessará a Tela de Cadastro de Empresas e Serviços.
              </Text>
            </VStack>
          </Box>

          <Box
            backgroundColor={theme.colors.white}
            rounded='md'
            padding={4}
          >
            <VStack space={2}>
              <Heading size='sm' color={theme.colors.primary[800]}>4) Insira os dados de sua empresa ou serviço</Heading>
              <Text>
                A Tela de Cadastro de Empresas e Serviços é bem simples!
                Você pode escolher o logo, título, banner, até 3 slides, descrição, categoria e colocar as informações de seus perfis nas redes sociais.
                Ao fim, é só clicar em Salvar e o registro será enviado para nossa Equipe de Análise.
                Assim que aprovado, sua empresa ou serviço será listada para a comunidade brasileira do BrasilAmericano!
              </Text>
            </VStack>
          </Box>

          <Heading textAlign='center' size='xs' color={theme.colors.primary[800]}>Parabéns! Você completou o tutorial de Cadastro de Empresa e Serviço!</Heading>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(connectActionSheet(TutorialCreateBusinessHelp));
