import React, { FC, memo, useCallback } from 'react';
import ContactMember from '../models/ContactMember.class';
import * as Analytics from 'expo-firebase-analytics'
import { Heading, Text, PresenceTransition, useTheme, VStack } from 'native-base';
import ActionIconButtons from './ActionIconButtons';
import {ActionSheetOptions} from '@expo/react-native-action-sheet'

interface ContactMemberListItemProps {
  member: ContactMember,
  showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void;
}

const ContactMemberListItem: FC<ContactMemberListItemProps> = ({member, showActionSheetWithOptions}) => {
  const theme = useTheme()

  const saveActionLog = useCallback((action: string) => {
    Analytics.logEvent('member_action', {
      memberId: member.id,
      action,
    })
    
    member.increaseRelevance()
  }, [member])

  return (
    <PresenceTransition visible={true} initial={{
      opacity: 0,
      scale: 0
    }} animate={{
      opacity: 1,
      scale: 1,
      transition: {
        duration: 250
      }
    }}>
      <VStack marginBottom={2} p={2} bgColor={theme.colors.white} rounded='md' space={2}>
        <Heading size="sm" fontWeight='normal' borderBottomColor={theme.colors.primary[800]} borderBottomWidth={1}>{member.name}</Heading>

        {member.role && member.role.length > 0 ? (
          <Text fontSize={'xs'}>{member.role}</Text>
        ) : null}

        {member.description && member.description.length > 0 ? (
          <Text fontWeight={'thin'} fontSize='xs'>{member.description}</Text>
        ) : null}

        <ActionIconButtons
          email={member.email}
          phone={member.phone}
          sms={member.sms}
          whatsapp={member.whatsapp}
          logCallback={saveActionLog}
          showActionSheetWithOptions={showActionSheetWithOptions}
        />
      </VStack>
    </PresenceTransition>
  )
}

export default memo(ContactMemberListItem);
