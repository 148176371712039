import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../components/AppHeader';
import Map from '../views/Map';
import ItemPage from '../views/ItemPage';
import CategoryDetails from '../views/CategoryDetails';

export type MapStackParamList = {
  map: undefined;
  category: { id: string };
  item: { id: string };
};

const Stack = createNativeStackNavigator<MapStackParamList>()

const MapNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='map' screenOptions={{
      header: (props) => <AppHeader initialScreen='map' {...props} />
    }}>
      <Stack.Screen name="map" component={Map} options={{
        title: 'BrasilAmericano | Mapa',
        headerShown: true
      }} />
      <Stack.Screen name="category" component={CategoryDetails} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
      }} />
      <Stack.Screen name="item" component={ItemPage} options={{
        title: 'BrasilAmericano | ...',
        headerTitle: '...',
        headerShown: false
      }} />
    </Stack.Navigator>
  )
}

export default memo(MapNavigator)
