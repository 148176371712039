import { collection, CollectionReference, getDocs, getFirestore, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { sortByOrderASC } from "../helpers/sort.helper"
import { Category } from "../models/Category.class"
import * as Analytics from 'expo-firebase-analytics'

interface UseCategoriesResponse {
  categories?: Category[]
}

const useCategories = (): UseCategoriesResponse => {
  const firestore = getFirestore()

  const [categories, setCategories] = useState<Category[]>()
  
  useEffect(() => {
    if (categories === undefined) {
      getDocs(query(collection(firestore, "categories") as CollectionReference<Category>, where('active', '==', true))).then(result => {
        const newCategories: Category[] = []
        
        result.forEach(doc => newCategories.push(new Category(doc)))

        newCategories.sort(sortByOrderASC)

        setCategories(newCategories)
      }).catch(e => {
        console.error(e)
      
        try {
          Analytics.logEvent('search_error', {
            method: 'getCategories',
            message: (e as Error).name ?? (e as Error).message
          })
        } catch (e) {
          console.error(e)
        }
        
        setCategories([])
      })
    }
  }, [categories, firestore])

  return {
    categories
  }
}

export default useCategories
