import { StackNavigationState, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { FC, useCallback, useEffect } from 'react';
import * as Analytics from 'expo-firebase-analytics';
import { TabParamsList } from '../navigators/MainNavigator';

interface StateRoute {
  name: string,
  params: any
}

const NavigatorListener: FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<TabParamsList>>()

  const getActiveRoute = useCallback((state: StackNavigationState<TabParamsList>): StateRoute | null => {
    if (!state) return null;

    let route;

    if (state.index) {
      route = state.routes[state.index];
    } else if (state.routes && Array.isArray(state.routes) && state.routes.length > 0) {
      route = state.routes[state.routes.length - 1]
    }

    if (!route) return null

    return {
      name: route.name,
      params: route.params
    };
  }, [])

  useEffect(() => {
    return navigation.addListener('state', (e) => {
      const activeRoute = getActiveRoute(e.data.state)

      if (activeRoute) {
        Analytics.logEvent('page_view', {
          page_title: activeRoute.name,
          params: activeRoute.params
        })
      }
    })
  }, [navigation]);

  return null;
}

export default NavigatorListener;
