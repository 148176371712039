import React, { FC, memo, useCallback, useState } from 'react';
import { View } from 'react-native';

import { ScrollView, VStack, FormControl, Input, WarningOutlineIcon, Button } from 'native-base';
import { isValidAuthCredentials, isValidEmail, isValidInput } from '../helpers/validate.helper';
import { ProfileAuthState } from './Profile';
import useAuth from '../hooks/useAuth';

export interface SignInCredentials {
  email: string
  password: string
}

export interface SignInProps {
  changeStage: (state: ProfileAuthState) => void
}

const SignIn: FC<SignInProps> = ({changeStage}) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  
  const {signIn} = useAuth()

  const goToSignUp = () => {
    changeStage('signup')
  }

  const goToResetPassword = () => {
    changeStage('resetpassword')
  }

  const handleSignInClick = useCallback(async () => {
    setSubmitted(true)
    setLoading(true)

    if (isValidAuthCredentials(email, password)) {
      try {
        await signIn(email, password)
      } catch {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [email, password, signIn])
  
  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <ScrollView style={{
        flex: 1,
        minHeight: '100%',
        padding: 20,
      }}>
        <VStack space={4} alignItems='center'>
          <FormControl isRequired isInvalid={submitted && !isValidEmail(email)}>
            <VStack>
              <Input type="text" variant={'rounded'} placeholder='Email' value={email} onChangeText={text => setEmail(text)} keyboardType="email-address" />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Por favor, insira um email válido.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={submitted && !isValidInput(password, 1)}>
            <VStack>
              <Input type="password" variant={'rounded'} placeholder='Senha' value={password} onChangeText={text => setPassword(text)} />
            </VStack>
          </FormControl>
          
          <Button isLoading={loading} isLoadingText='Entrando' width={'full'} rounded='full' onPress={handleSignInClick}>
            Entrar
          </Button>

          <Button width={'full'} rounded='full' variant='ghost' onPress={goToResetPassword} disabled={loading}>
            Esqueci minha senha
          </Button>

          <Button width={'full'} rounded='full' variant='outline' onPress={goToSignUp} disabled={loading}>
            Criar Nova Conta
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(SignIn);
