import { collection, CollectionReference, DocumentSnapshot, GeoPoint, getDocs, query, where, runTransaction, DocumentReference } from "firebase/firestore";
import { CategoryRef, SubCategory } from "./Category.class";
import { CityRef } from "./City.class";
import ContactMember from "./ContactMember.class";
import { FirestoreSnapshot } from "./FirestoreSnapshot.class";
import { StateRef } from "./State.class";
import * as Analytics from 'expo-firebase-analytics'
import { UserPermission, UserPermissionType } from "./User.class";
import moment from 'moment'

export interface ItemPermission {
  ref: DocumentReference<Item>
  permission: UserPermissionType
}

export interface ItemCoupon {
  name: string
  amount: number
  prefix?: string | null
  suffix?: string | null
  active: boolean
  createdAt: Date
  deletedAt?: Date | null
}

export interface ItemRef {
  ref: DocumentReference<Item>
}

export default class Item extends FirestoreSnapshot<Item> {
  name: string
  nameLowerCase: string
  description: string
  active: boolean
  waitingForApproval?: boolean | null
  relevance: number
  type?: string | null
  model?: string | null

  logo?: string | null
  banner?: string | null
  slides: string[]
 
  phone?: string | null
  sms?: string | null
  whatsapp?: string | null
  email?: string | null
  website?: string | null

  facebook?: string | null
  instagram?: string | null
  linktree?: string | null
  tiktok?: string | null
  linkedin?: string | null
  youtube?: string | null

  address1?: string | null
  address2?: string | null
  zipCode?: string | null
  coords: GeoPoint | null

  createdAt: Date
  updatedAt?: Date
  deletedAt?: Date
  
  state?: StateRef | null
  city?: CityRef | null
  category: CategoryRef
  subCategories?: SubCategory[]

  membersQuantity: number
  members?: ContactMember[]

  addressLine: string = ''

  permissions?: UserPermission[] | null

  coupons?: ItemCoupon[] | null

  verified: boolean

  constructor(doc: DocumentSnapshot<Item>) {
    super(doc)
    
    const data = doc.data()!
    
    this.name = data.name
    this.nameLowerCase = data.nameLowerCase
    this.description = data.description
    this.type = data.type
    this.model = data.model
    this.active = data.active
    this.relevance = data.relevance ?? 0
    
    this.logo = data.logo
    this.banner = data.banner
    this.slides = data.slides ?? []

    this.phone = data.phone
    this.sms = data.sms
    this.whatsapp = data.whatsapp
    this.email = data.email
    this.website = data.website

    this.facebook = data.facebook
    this.instagram = data.instagram
    this.linktree = data.linktree
    this.tiktok = data.tiktok
    this.linkedin = data.linkedin
    this.youtube = data.youtube

    this.address1 = data.address1
    this.address2 = data.address2
    this.zipCode = data.zipCode
    this.coords = data.coords

    this.state = data.state
    this.city = data.city
    this.category = data.category
    this.subCategories = data.subCategories
    this.membersQuantity = data.membersQuantity ?? 0
    this.permissions = data.permissions
    this.coupons = data.coupons

    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.deletedAt = data.deletedAt

    this.verified = data.verified ?? false

    if (this.address1) {
      this.addressLine = `${this.address1}${this.address2 && this.address2.length > 0 ? `, ${this.address2}` : ''}, ${this.city?.name}, ${this.state?.name}${this.zipCode && this.zipCode.length > 0 ? ` ${this.zipCode}` : ''}`
    }
  }

  async fetchMembers(): Promise<ContactMember[]> {
    this.members = []
    
    try {
      const result = await getDocs(query(collection(this.snapshot.ref.firestore, `${this.snapshot.ref.path}/members`) as CollectionReference<ContactMember>, where('active', '==', true)))
      
      result.forEach(doc => this.members?.push(new ContactMember(doc)))
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('item_error', {
          method: 'fetchMembers',
          id: this.id,
          message: (e as Error).name ?? (e as Error).message
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.members
  }

  async increaseRelevance(): Promise<number> {
    try {
      this.relevance = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          return this.relevance
        }

        const newRelevance = doc.data().relevance + 1
        transaction.update(this.snapshot.ref, {
          relevance: newRelevance,
          updatedAt: moment().utc().toDate(),
        })

        return newRelevance
      })
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('item_error', {
          method: 'increaseRelevance',
          id: this.id,
          message: (e as Error).name ?? (e as Error).message
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.relevance
  }
}
