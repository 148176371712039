import { isUndefined } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { browserLocalPersistence, createUserWithEmailAndPassword, getAuth, setPersistence, signInWithEmailAndPassword, User as FirebaseUser, signOut as firebaseSignOut, AuthErrorCodes, sendPasswordResetEmail } from 'firebase/auth'
import { Favorite, User } from '../models/User.class';
import { getFirestore, getDoc, DocumentReference, doc, setDoc, deleteDoc } from 'firebase/firestore';
import * as Analytics from 'expo-firebase-analytics'
import { Box, useTheme, useToast, Text } from 'native-base';
import Item from '../models/Item.class';
import { useDispatch, useSelector } from 'react-redux'
import { setFavorites, setUser, setUserData } from '../redux/auth';
import { getFavorites, getUser, getUserData } from '../redux/auth/selectors';
import moment from 'moment';

const useAuth = () => {
  const auth = getAuth();
  const firestore = getFirestore()
  const toast = useToast()
  const theme = useTheme()
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const userData = useSelector(getUserData)
  const favorites = useSelector(getFavorites)

  const toastAuthError = useCallback((errorCode?: typeof AuthErrorCodes | null) => {
    let errorMessage = ''
    
    switch (errorCode ?? '') {
      case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      case AuthErrorCodes.EMAIL_CHANGE_NEEDS_VERIFICATION:
        errorMessage = 'Por favor, refaça o login.'
        break

      case AuthErrorCodes.UNVERIFIED_EMAIL:
        errorMessage = 'Por favor, verifique seu email. Um email de verificação foi enviado à sua conta.'
        break

      case AuthErrorCodes.USER_CANCELLED:
      case AuthErrorCodes.USER_DELETED:
      case AuthErrorCodes.USER_DISABLED:
        errorMessage = 'Ops! Sua conta foi desativada. Por favor, contate o suporte para mais informações.'
        break

      case AuthErrorCodes.INVALID_PASSWORD:
        errorMessage = 'Ops! Senha inválida. Por favor, tente novamente. Caso não se lembre de sua senha antiga, tente redefini-la.'
        break

      default:
        errorMessage = 'Ops! Um error ocorreu. Por favor, tente novamente ou contate nosso suporte.'
        break
    }

    if (toast) {
      try {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4}>
              <Text textAlign='center' color='white'>{errorMessage}</Text>
            </Box>
          )
        })
      } catch (e) {
        alert(errorMessage)
      }
    }
  }, [toast, theme])

  const signUp = useCallback(
    async (email: string, password: string): Promise<FirebaseUser | null> => {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        
        if (userCredential) {
          dispatch(setUser(userCredential.user))
          return userCredential.user
        } else {
          dispatch(setUser(null))
        }

        return null
      } catch (e) {
        console.error(e);

        toastAuthError((e as any).code)
        
        try {
          Analytics.logEvent('signUp_error', {
            method: 'signUp',
            email,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
        
        throw e;
      }
    },
    [auth, toastAuthError, dispatch],
  );

  const signIn = useCallback(
    async (email: string, password: string) => {
      try {
        await setPersistence(auth, browserLocalPersistence)
        const userCredential = await signInWithEmailAndPassword(auth, email, password)

        dispatch(setFavorites(undefined))

        if (userCredential) {
          dispatch(setUser(userCredential.user))
        } else {
          dispatch(setUser(null))
        }
      } catch (e) {
        console.error(e)

        toastAuthError((e as any).code)

        try {
          Analytics.logEvent('signIn_error', {
            method: 'signIn',
            email,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
        
        throw e
      }
    },
    [auth, toastAuthError, dispatch],
  );

  const signOut = useCallback(async () => {
    try {
      await firebaseSignOut(auth)
      dispatch(setFavorites(undefined))
      dispatch(setUser(null));
    } catch (e) {
      console.error(e);

      try {
        Analytics.logEvent('signIn_error', {
          method: 'signIn',
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [auth, dispatch]);

  const resetPassword = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email)
    } catch (e) {
      console.error(e)

      toastAuthError((e as any).code)

      try {
        Analytics.logEvent('signIn_error', {
          method: 'signIn',
          email,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
      
      throw e
    }
  }, [auth])

  const isItemFavorited = useCallback((item: Item) => {
    return !!favorites?.find(favorite => favorite.id === item.snapshot.id)
  }, [favorites])

  const addFavorite = useCallback(async (ref: DocumentReference<Item>) => {
    if (!user || !firestore || isUndefined(favorites)) {
      return
    }

    const newFavorites: DocumentReference<Item>[] = Array.from(favorites ?? [])
    newFavorites.push(ref)
    dispatch(setFavorites(newFavorites))

    try {
      const userDoc = doc(firestore, 'users', user.uid)
      const favoriteDoc = doc(firestore, userDoc.path, 'favorites', ref.id) as DocumentReference<Favorite>
      
      await setDoc(favoriteDoc, {
        createdAt: moment().utc().toDate(),
        itemRef: ref,
        userRef: userDoc
      })
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('useAuth_error', {
          method: 'useAuth_addFavorite',
          id: user.uid,
          itemId: ref.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [user, firestore, favorites, dispatch])

  const removeFavorite = useCallback(async (ref: DocumentReference<Item>) => {
    if (!user || !firestore || isUndefined(favorites)) {
      return
    }

    const newFavorites: DocumentReference<Item>[] = Array.from(favorites ?? [])
    const favoriteIndex = newFavorites.findIndex(favorite => favorite.id === ref.id)

    if (favoriteIndex >= 0) {
      newFavorites.splice(favoriteIndex, 1)
      dispatch(setFavorites(newFavorites))
    }

    try {
      const favoriteDoc = doc(firestore, 'users', user.uid, 'favorites', ref.id) as DocumentReference<Favorite>

      await deleteDoc(favoriteDoc)
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('useAuth_error', {
          method: 'useAuth_removeFavorite',
          id: user.uid,
          itemId: ref.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [user, firestore, favorites, dispatch])

  useEffect(() => {
    if (firestore && user && (isUndefined(userData) || (userData && userData.id === user.uid))) {
      getDoc(doc(firestore, 'users', user.uid) as DocumentReference<User>).then(result => {
        if (!result.exists()) {
          dispatch(setUserData(null))
          return
        }

        const userData = new User(result)
        dispatch(setUserData(userData))

        userData.fetchFavoriteItemRefs().then(itemRefs => dispatch(setFavorites(itemRefs))).catch()
      })
    }
  }, [user, firestore, dispatch])

  return {
    signUp,
    signIn,
    signOut,
    resetPassword,
    isItemFavorited,
    addFavorite,
    removeFavorite,
  };
};

export default useAuth;
