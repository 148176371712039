import React, { FC, memo, useCallback, useState } from 'react';
import { View } from 'react-native';

import { ScrollView, VStack, FormControl, Input, WarningOutlineIcon, Button, useToast, Box, useTheme, Text } from 'native-base';
import { ProfileAuthState } from './Profile';
import { isValidEmail } from '../helpers/validate.helper';
import useAuth from '../hooks/useAuth';

export interface ResetPasswordProps {
  changeStage: (state: ProfileAuthState) => void
}

const ResetPassword: FC<ResetPasswordProps> = ({changeStage}) => {
  const toast = useToast()
  const theme = useTheme()

  const [email, setEmail] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  
  const {resetPassword} = useAuth()

  const goToSignIn = () => {
    changeStage('signin')
  }

  const handleResetPasswordClick = useCallback(async () => {
    setSubmitted(true)
    setLoading(true)

    if (isValidEmail(email)) {
      try {
        await resetPassword(email)

        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4}>
              <Text textAlign='center' color='white'>Um email para redefinição de senha foi enviado!</Text>
            </Box>
          )
        })

        goToSignIn()
      } catch {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [email, resetPassword, toast, theme])
  
  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <ScrollView style={{
        flex: 1,
        minHeight: '100%',
        padding: 20,
      }}>
        <VStack space={4} alignItems='center'>
          <FormControl isRequired isInvalid={submitted && !isValidEmail(email)}>
            <VStack>
              <Input type="text" variant={'rounded'} placeholder='Email' value={email} onChangeText={text => setEmail(text)} keyboardType='email-address' />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Por favor, insira um email válido.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <Button isLoading={loading} isLoadingText='Entrando' width={'full'} rounded='full' onPress={handleResetPasswordClick}>
            Redefinir Senha
          </Button>

          <Button width={'full'} rounded='full' variant='outline' onPress={goToSignIn} disabled={loading}>
            Cancelar
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(ResetPassword);
