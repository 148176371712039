import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { HStack, useTheme, Text, Pressable, PresenceTransition } from 'native-base';
import React, { FC, memo, useCallback } from 'react';
import { getIcon } from '../helpers/icon.helper';
import { Category } from '../models/Category.class';
import { HomeStackParamList } from '../navigators/HomeNavigator';

interface CategoryListItemProps {
  category: Category,
}

const CategoryListItem: FC<CategoryListItemProps> = ({category}) => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<HomeStackParamList>>()

  const handleCategoryPress = useCallback(() => {
    if (category) {
      navigation.push('category', {
        id: category.id
      })
    }
  }, [ category, navigation ]);

  return (
    <PresenceTransition visible={true} initial={{
      opacity: 0,
      scale: 0
    }} animate={{
      opacity: 1,
      scale: 1,
      transition: {
        duration: 250
      }
    }}>
      <Pressable onPress={handleCategoryPress}>
        <HStack 
          space={4}
          p={2}
          paddingLeft={4}
          borderRadius="full"
          bgColor={theme.colors.white}
          marginBottom={2}
          alignItems='center'
          h={'41px'}
        >
          {getIcon(category.icon, {
            color: theme.colors.primary[800],
            size: 25,
          })}

          <Text fontSize={'sm'} fontWeight={'thin'} numberOfLines={1}>{category.name}</Text>
        </HStack>
      </Pressable>
    </PresenceTransition>
  )
}

export default memo(CategoryListItem);
