import { HStack, VStack, Text, Pressable, useTheme, Box } from 'native-base';
import React, { FC, memo } from 'react';
import { getIcon, GetIconProps } from '../helpers/icon.helper';

export interface ProfileListItemProps {
  leftIcon: GetIconProps & {
    iconName: string
  }
  title: string
  titleColor?: string
  description?: string
  action: () => void
}

const ProfileListItem: FC<ProfileListItemProps> = ({leftIcon, title, titleColor, description, action}) => {
  const theme = useTheme()

  return (
    <Pressable onPress={action}>
      <HStack width='full' space={4} justifyContent='space-between' alignItems='flex-start'>
        {getIcon(leftIcon.iconName, leftIcon)}
        
        <VStack flex={1}>
          <Text fontSize='md' color={titleColor ?? theme.colors.gray[600]}>{title}</Text>
          
          {description ? (
            <Text fontSize='xs' color={theme.colors.gray[500]}>{description}</Text>
          ) : null}
        </VStack>

        <VStack height={'full'} justifyContent='center' alignItems={'flex-end'}>
          {
            getIcon('etp/chevron-small-right', {
              size: 24,
              color: theme.colors.gray[400]
            })
          }
        </VStack>
      </HStack>
    </Pressable>
  );
}

export default memo(ProfileListItem);
