import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import React, { FC, memo } from 'react';
import NavigatorListener from '../components/NavigatorListener';
import LeftDrawerNavigator from './LeftDrawerNavigator';

export type RootParamsList = {
  mainNavigator: undefined;
  helpNavigator: undefined;
};

const linking: LinkingOptions<RootParamsList> = {
  prefixes: ['brasilamericano://', 'https://brasilamericano.com'],
  config: {
    screens: {
      mainNavigator: {
        path: '',
        screens: {
          homePrincipal: {
            path: 'home',
            screens: {
              home: '',
              category: 'categoria/:id',
              item: 'pagina/:id'
            }
          },
          searchPrincipal: {
            path: 'buscar',
            screens: {
              search: '',
              category: 'categoria/:id',
              item: 'pagina/:id'
            }
          },
          mapPrincipal: {
            path: 'mapa',
            screens: {
              map: '',
              category: 'categoria/:id',
              item: 'pagina/:id'
            }
          },
          userPrincipal: {
            path: 'perfil',
            screens: {
              profile: '',
              editProfile: 'meus-dados',
              favorites: 'favoritos',
              category: 'categoria/:id',
              item: 'pagina/:id'
            }
          }
        }
      },
      helpNavigator: {
        path: 'central-de-ajuda',
        screens: {
          help: {
            path: '',
          },
          createBusiness: {
            path: 'como-registrar-sua-empresa-ou-servico'
          },
          claimBusiness: {
            path: 'como-reivindicar-uma-empresa-ou-servico'
          }
        }
      }
    }
  }
}

const RootNavigator: FC = () => {

  return (
    <NavigationContainer linking={linking}>
      <LeftDrawerNavigator />
      <NavigatorListener />
    </NavigationContainer>
  );
}

export default memo(RootNavigator);
