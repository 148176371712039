import { User as FirebaseUser } from 'firebase/auth';
import { DocumentReference } from 'firebase/firestore';
import { User } from '../../models/User.class';
import Item from './../../models/Item.class';
import { RootState } from './../root-state';

export const getUser = (state: RootState): FirebaseUser | null | undefined => state.auth.user

export const getUserData = (state: RootState): User | null | undefined => state.auth.userData

export const getFavorites = (state: RootState): DocumentReference<Item>[] | undefined => state.auth.favorites
