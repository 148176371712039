import { collection, CollectionReference, DocumentReference, DocumentSnapshot, getDocs, query, where, documentId, doc } from "firebase/firestore"
import { FirestoreSnapshot } from "./FirestoreSnapshot.class"
import Item, { ItemRef } from "./Item.class"
import * as Analytics from 'expo-firebase-analytics'

export type UserRole = 'admin' | 'mod' | 'user'
export type UserPermissionType = 'owner' | 'admin' | 'mod'

export interface UserRef {
  firstName: string
  email: string
  ref: DocumentReference<User>
}

export interface UserPermission extends UserRef {
  permission: UserPermissionType
}

export interface Favorite {
  createdAt: Date
  itemRef: DocumentReference<Item>
  userRef: DocumentReference<User>
}

export class User extends FirestoreSnapshot<User> {
  firstName: string
  lastName: string
  photo: string
  email: string
  role: UserRole
  createdAt: Date
  updatedAt?: Date | null

  constructor(doc: DocumentSnapshot<User>) {
    super(doc)
    
    const data = doc.data()!
    
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.photo = data.photo
    this.email = data.email
    this.role = data.role
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
  }

  async fetchFavoriteItemRefs(): Promise<DocumentReference<Item>[]> {
    try {
      const favorites = await getDocs(query(collection(this.snapshot.ref.firestore, this.snapshot.ref.path, 'favorites') as CollectionReference<Favorite>))

      const itemIDs = favorites.docs.map(favoriteDoc => favoriteDoc.id)

      return itemIDs.map(itemId => doc(this.snapshot.ref.firestore, 'items', itemId) as DocumentReference<Item>)
    } catch (e) {
      console.error(e);

      try {
        Analytics.logEvent('user_error', {
          method: 'fetchFavoriteItemRefs',
          userId: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      return []
    }
  }

  async fetchFavoriteItems(): Promise<Item[]> {
    const favoriteItems: Item[] = [] 

    try {
      const favorites = await getDocs(query(collection(this.snapshot.ref.firestore, this.snapshot.ref.path, 'favorites') as CollectionReference<Favorite>))

      const itemIDs = favorites.docs.map(favoriteDoc => favoriteDoc.id)

      const items = await getDocs(query(collection(this.snapshot.ref.firestore, 'items') as CollectionReference<Item>, where('active', '==', true), where(documentId(), 'in', itemIDs)))
      
      items.forEach(itemDoc => favoriteItems.push(new Item(itemDoc)))
    } catch (e) {
      console.error(e);

      try {
        Analytics.logEvent('user_error', {
          method: 'fetchFavoriteItems',
          userId: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      return favoriteItems
    }
  }
}
