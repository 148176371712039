import { isNil } from 'lodash'

export const PASSWORD_MIN_LENGTH = 6

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i // eslint-disable-line no-useless-escape
  const result = email.toLowerCase().match(emailRegex)
  return !!result && result.length > 0
}

export const isValidInput = (text: string, minLength: number = 0): boolean => {
  return !isNil(text) && text.trim().length >= minLength
}

export const isValidPassword = (password: string): boolean => isValidInput(password, PASSWORD_MIN_LENGTH)

export const isValidAuthCredentials = (email: string, password: string): boolean => {
  return isValidEmail(email) && isValidInput(password, PASSWORD_MIN_LENGTH)
}

export const isValidSignUpCredentials = (params: {
  email: string,
  password: string,
  confirmPassword: string,
  firstName: string,
  lastName: string
}): boolean => {
  return (
    isValidEmail(params.email) &&
    isValidPassword(params.password) &&
    isValidPassword(params.confirmPassword) &&
    params.password === params.confirmPassword &&
    isValidInput(params.firstName, 1) &&
    isValidInput(params.lastName, 1)
  )
}
