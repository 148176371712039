import { IconButton } from 'native-base'
import React, {FC, memo} from 'react'
import { getIcon } from '../helpers/icon.helper'

export interface BackButtonProps {
  isVisible: boolean
  icon: string
  action: () => void
  iconColor?: string
}

const GoBackIconButton: FC<BackButtonProps> = ({icon, action, iconColor: color}) => {
  return (
    <IconButton
      variant='unstyled'
      onPress={action}
      icon={
        getIcon(icon, {size: 20, color}) ?? undefined
      }
    />
  )
}

export default memo(GoBackIconButton)
