import { MaterialIcons } from '@expo/vector-icons';
import { Badge, Icon, useTheme } from 'native-base';
import React, { FC, memo } from 'react';

const VerifiedBadge: FC = () => {

  const theme = useTheme()

  return (
    <Badge
      variant='solid'
      rounded={'full'}
      bgColor={theme.colors.info[400]}
      leftIcon={
        <Icon 
          as={MaterialIcons} 
          name='verified' 
          color={theme.colors.white} 
          size={4} 
        />
      }
      _text={{
        fontWeight: 'bold'
      }}
    >
      Conta Verificada
    </Badge>
  );
}

export default memo(VerifiedBadge);
