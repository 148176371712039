import {Dimensions, Platform} from 'react-native';

export const IS_IOS = Platform.OS === 'ios';
export const {width: viewportWidth, height: viewportHeight} = Dimensions.get('window');

export function wp(percentage: number) {
  const value = (percentage * viewportWidth) / 100;
  return Math.round(value);
}

export const slideHeight = viewportHeight * 0.42;
export const slideWidth = wp(75);
export const minSlideWidth = wp(30);
export const itemHorizontalMargin = wp(2);

export const sliderWidth = viewportWidth;
export const itemWidth = slideWidth + itemHorizontalMargin * 2;

export const entryBorderRadius = 8;

export const leftIconListTitleSize = 16;

export const spacing = 16
