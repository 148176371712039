import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer';
import { Button, useTheme, VStack, Icon, HStack, IconButton, Text } from 'native-base';
import React, { FC, memo, useMemo } from 'react';
import { AntDesign, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { openLink } from '../helpers/link.helper';
import moment from 'moment';

interface LeftDrawerSection {
  type: 'button' | 'section',
  children?: LeftDrawerSection[],
  name: string
  icon?: {
    family: any,
    name: string
  }
  action?: () => void
}

interface SocialButon {
  name: string
  family: any,
  action: () => void
}

const LeftDrawerButton: FC<LeftDrawerSection> = ({
  name, icon, action,
}) => {
  const theme = useTheme()

  return (
    <Button
      key={name}
      rounded='full'
      bgColor='white'
      leftIcon={icon ? (
        <Icon as={icon.family} name={icon.name} marginRight={2} color={theme.colors.primary[800]} />
      ) : undefined}
      onPress={action}
      justifyContent='flex-start'
      _text={{
        color: theme.colors.black,
        noOfLines: 2
      }}
      flexWrap='wrap'
    >
      {name}
    </Button>
  )
}

const LeftDrawerSectionView: FC<LeftDrawerSection> = ({
  name, icon, children
}) => {
  const theme = useTheme()

  return (
    <VStack key={name} bgColor='white' rounded='lg' space={2} width='full' paddingX='12px' paddingY='10px'>
      <HStack width='full' space={3} alignItems='center' borderBottomWidth={1} paddingBottom={2} borderBottomColor={theme.colors.primary[800]}>
        {icon ? (
          <Icon as={icon.family} name={icon.name} color={theme.colors.primary[800]} />
        ) : undefined}

        <Text color={theme.colors.black}>{name}</Text>
      </HStack>

      {children?.map(child => <LeftDrawerButton {...child} />)}
    </VStack>
  )
}

const LeftDrawerContent: FC<DrawerContentComponentProps> = ({navigation}) => {
  const theme = useTheme()

  const sections = useMemo<LeftDrawerSection[]>(() => {
    return [
      {
        type: 'button',
        name: 'Home',
        icon: {
          family: Ionicons,
          name: 'md-home'
        },
        action: () => navigation.navigate('mainNavigator')
      },
      {
        type: 'button',
        name: 'Portal do Empreendedor',
        icon: {
          family: MaterialCommunityIcons,
          name: 'briefcase-variant'
        },
        action: () => openLink('https://admin.brasilamericano.com')
      },
      {
        type: 'button',
        name: 'Central de Ajuda',
        icon: {
          family: MaterialCommunityIcons,
          name: 'help-box'
        },
        action: () => navigation.navigate('helpNavigator', {
          screen: 'help'
        })
      }
    ]
  }, [navigation])

  const socialButtons = useMemo<SocialButon[]>(() => {
    return [
      {
        name: 'email',
        family: MaterialCommunityIcons,
        action: () => openLink('mailto:contato@brasilamericano.com')
      },
      {
        name: 'instagram',
        family: AntDesign,
        action: () => openLink('https://www.instagram.com/brasilamericanoapp')
      },
      {
        name: 'twitter',
        family: AntDesign,
        action: () => openLink('https://twitter.com/BRAmericanoApp')
      },
      {
        name: 'tiktok',
        family: FontAwesome5,
        action: () => openLink('https://www.tiktok.com/@brasilamericanoapp')
      },
    ]
  }, [])

  return (
    <VStack height='full' bgColor={'rgb(242, 242, 242)'} space={2} p={2}>
      <DrawerContentScrollView style={{ flex: 1 }}>
        <VStack width={'full'} space={2}>
          {sections.map((section) => {
            if (section.type === 'button') {
              return <LeftDrawerButton key={section.name} {...section} />
            }

            return <LeftDrawerSectionView key={section.name} {...section} />
          })}
        </VStack>
      </DrawerContentScrollView>
      <HStack width='full' justifyContent='center' alignItems={'center'} space={2}>
        {socialButtons.map(({name, family, action}) => (
          <IconButton
            key={name}
            bgColor='white'
            icon={<Icon as={family} name={name} size={5} color={theme.colors.primary[800]} />}
            variant='solid'
            rounded='full'
            onPress={action}
          />
        ))}
      </HStack>
      <VStack width={'full'}>
        <Text fontSize='xs' width={'full'} textAlign='center' alignItems={'center'}>
          <Icon as={MaterialCommunityIcons} size='xs' name='copyright' marginRight={1} /> 
          {`${moment().year()} BrasilAmericano`}
        </Text>
        <Text fontSize='xs' width={'full'} textAlign='center'>Todos os direitos reservados</Text>
      </VStack>
    </VStack>
  );
}

export default memo(LeftDrawerContent);
